import { useEffect, useState } from 'react';
import CommonProductList from '../ProductList/CommonProductList';
import { BannerType, Product } from '../../types/ResponseTypes';
import { useStore } from '../../store/store';
import { observer } from 'mobx-react';
import FlashAlert from '../FlashAlert/FlashAlert';
import JerseyConstructor from '../JerseyConstructor/JerseyConstructor';
import Banner from '../Banner/Banner';
import { getPopup } from '../../api/LayoutApi';
import Popup from '../Popup/Popup';

const Home = observer(() => {
    const [topWeek, setTopWeek] = useState<Array<Product>>([]);
    const [novelties, setNovelties] = useState<Array<Product>>([]);
    const [flashAlertMessage, setFlashAlertMessage] = useState<string>('');
    const [popup, setPopup] = useState<BannerType>();
    const [openPopup, setOpenPopup] = useState(false);
    const { productStore } = useStore();

    useEffect(() => {
        const getData = async () => {
            const resp = await getPopup();
            setPopup(resp.data.data[0]);
        }
        getData();
    }, []);

    useEffect(() => {
        if (popup) {
            setOpenPopup(true);
        }
    }, [popup])

    useEffect(() => {
        const getData = async () => {
            setTopWeek(productStore.topWeek);
            setNovelties(productStore.novelties);

            // setFlashAlertMessage(`
            // <p><b>В данный момент магазин работает в тестовом режиме.</b> <br><br>
            
            //     В случае, если вы испытываете сложности со входом, перейдите по <a href='/login/lost-password'>ссылке</a> для смены пароля.</li> <br>
            //     Бонусная программа временно не работает. <br><br>

            //     По всем вопросам касательно заказов и бонусной программы обращаться к менеджеру магазина.<br>
            //     <b>Приносим извинения за доставленные неудобства.</b>
            // </p>
            // `);
        }
        getData();
    }, [productStore.topWeek, productStore.novelties]);

    return (
        <div className='container hp-products home-page'>
            {
                flashAlertMessage &&
                <FlashAlert message={flashAlertMessage} close={()=>{setFlashAlertMessage('')}} />
            }
            { popup && openPopup && <Popup popup={popup} closePopup={() => setOpenPopup(false)} /> }
            <Banner />
            <JerseyConstructor />
            <CommonProductList header='ТОВАРЫ НЕДЕЛИ' products={topWeek} />
            <CommonProductList header='Новинки' products={novelties} />
        </div>
    );
});

export default Home;
