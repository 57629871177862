import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useStore } from '../../store/store';
import FlashAlert from '../FlashAlert/FlashAlert';
import OrderPipe from '../OrderPipe/OrderPipe';
import ProductItem from './ProductItem/ProductItem';

const VerificationPage = observer(() => {
    const { orderStore } = useStore();
    const [ error, setError ] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const navigate = useNavigate();

    useEffect(() => {
        const getData = async () => {
            orderStore.loadCurrentOrder();
            await orderStore.updatePrices();
        }

        getData()
    }, []);

    const onSubmit = async () => {
        try {
            if (!isLoading) {
                setIsLoading(true);
                await orderStore.saveCurrentOrderToServer();
                await orderStore.clearCurrentOrder();

                navigate("/cart/order-finished");
            }

        } catch (e: any) {
            setIsLoading(false);
            const error = e.response.data?.error;
            const details = error?.details;
            if (error && error.message) {
                setError(`Произошла ошибка: ${error.message}.`);
            } else {
                setError(`Произошла ошибка. Попробуйте позже`);
            }
        }

    }

    function getComment() {
        if (orderStore.currentOrder.original_delivery_cost === 0 && orderStore.currentOrder.delivery_method.id !== 4 ) {
            return '* Произошла ошибка. Стоимость доставки уточняется менеджером при подтверждении заказа.';
        }

        return '';
    }

    return (
        <div className='container layout'>
            <main className='row'>
                <div className='col-sm-12 content content-full content-left'>
                    {error && <FlashAlert message={error} close={() => setError("")}/> }
                    <div className='cart-heading'>
                        <OrderPipe />
                    </div>
                    <div className='cart'>
                        <div className='recap'>
                            <div className='items'>
                                <table className='table'>
                                    <tbody>
                                        <tr>
                                            <th colSpan={2}>Товар</th>
                                            <th className='center'>НДС</th>
                                            <th className='center'>Стоимость за шт.</th>
                                            <th className='center'>Количество</th>
                                            <th className='center'>Итого</th>
                                        </tr>
                                        {
                                            orderStore.cartStore.cartItems && orderStore.cartStore.cartItems.map((item) => <ProductItem key={item.id} tax={item.tax.percent_value} orderItem={item} />)
                                        }
                                        <tr>
                                            <td></td>
                                            <td className='left'>Доставка - { orderStore.currentOrder && orderStore.currentOrder.delivery_method.name}</td>
                                            <td className='center'>-</td>
                                            {
                                                orderStore?.currentOrder?.real_delivery_cost !== orderStore?.currentOrder?.original_delivery_cost ?
                                                <td className='center'>
                                                    <span className='old-price'>{ orderStore?.currentOrder?.original_delivery_cost } Р</span>
                                                    <span className='new-price'>  { orderStore?.currentOrder?.real_delivery_cost } Р</span>
                                                </td> :
                                                <td className='center'>{ orderStore?.currentOrder?.real_delivery_cost }{orderStore?.currentOrder?.original_delivery_cost === 0 ? '*' : ''}</td>
                                            }
                                            <td className='center'>1x</td>
                                            <td className='center'>{ orderStore?.currentOrder?.real_delivery_cost } Р</td>
                                        </tr>
                                        {
                                            orderStore.currentOrder.bonus_to_spend > 0 &&
                                            <tr>
                                                <td></td>
                                                <td className='left'>Использовано бонусов</td>
                                                <td className='center'>-</td>
                                                <td className='center'>-{ orderStore.currentOrder.bonus_to_spend } P</td>
                                                <td className='center'>1x</td>
                                                <td className='center'>-{ orderStore.currentOrder.bonus_to_spend } P</td>
                                            </tr>
                                        }
                                    </tbody>
                                </table>
                                <p>{getComment()}</p>
                            </div>
                            <div className='price right'>
                                <div className='totalprice'>Итого: {orderStore.currentOrder && orderStore.currentOrder.total_price_with_tax - orderStore.currentOrder.bonus_to_spend} P</div>
                            </div>
                            <div className='navigation cfx'>
                                <div className='l'>
                                    <Link className='inline-btn btn btn-secondary' to='/cart/delivery-payment'>
                                        <span>◀ Доставка и оплата</span>
                                    </Link>
                                </div>
                                <div className='r'>
                                    <a className='inline-btn btn btn-secondary' id='preventmoreclick' onClick={onSubmit}>
                                        <span>Завершение заказа ▶</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
});

export default VerificationPage;
