import { ReactElement, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getUrlToImage } from "../../../api/ApiHelper";
import { getJerseyPrice } from "../../../api/LayoutApi";
import { getCustomisationText } from "../../../helpers/helpers";
import { CartItem, OrderItem } from "../../../types/ResponseTypes";

type ProductItemProps = {
    orderItem: CartItem,
    tax: number
}

function ProductItem({ orderItem, tax }: ProductItemProps): ReactElement {
    const [printedPrice, setPrintedPrice] = useState<number>(orderItem.product.price);

    useEffect(() => {
        const getData = async () => {
            if (orderItem.customisationref) {
                const data = await getJerseyPrice(orderItem.product.id, orderItem.customisationref);
                if (data) {
                    setPrintedPrice(data.data);
                }
            }
        }
        getData();
    }, []);

    function getLinkToProduct() {
        return `/product/${orderItem.product.uuid_1c}`;
    }

    return (
        <tr>
            <td className='left'>
                <Link to={getLinkToProduct()}>
                    <span className='image centerall'>
                        <img className='custom-cart-image' src={getUrlToImage(orderItem.picture_url)} alt={orderItem.product.name} title={orderItem.product.name} />
                    </span>
                </Link>
            </td>
            <td className='left'>
                <Link to={getLinkToProduct()}>
                    <strong>{orderItem.product.name}</strong>
                    {
                        orderItem.customisationref && <p>{getCustomisationText(orderItem)}</p>
                    }
                </Link>
            </td>
            <td className='center'>{tax}%</td>
            <td className='center'>{printedPrice} P</td>
            <td className='center'>{orderItem.amount} шт</td>
            <td className='center'>{orderItem.position_price} P</td>
        </tr>
    )
}

export default ProductItem;
