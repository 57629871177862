import { ReactElement, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import FlashAlert from '../FlashAlert/FlashAlert';
import { forgotPassword } from '../../api/LayoutApi';

type Input = {
    email: string
};
const schema = yup.object().shape({
    email: yup.string().required('Пожалуйста, введите Ваш email')
});

function LostPasswordPage(): ReactElement {
    const [ isSuccess, setIsSuccess ] = useState<boolean>(false);
    const { register, handleSubmit, setError, formState: { errors }, clearErrors } = useForm<Input>({
        resolver: yupResolver(schema),
    });
    const onSubmit = handleSubmit(async (data) => {
        try {
            const response = await forgotPassword(data);
            if (response.data.ok) {
                setIsSuccess(true);
            }
        } catch (e) {
            setError('email', {
                type: 'server',
                message: 'Something error' // TODO: add more message
            });
        }
    });

    function closeError() {
        clearErrors('email');
    }

    function closeFlashGreenAlert() {
        setIsSuccess(false);
    }

    return (
        <div>
            { 
                errors.email &&
                <FlashAlert message={errors.email?.message} close={closeError} />
            }
            { 
                isSuccess &&
                <FlashAlert message={'Email был отправлен'} close={closeFlashGreenAlert} isGreen={true} />
            }
            <div className="container layout">
                <main className="row row-mg0">
                    <div className="col-sm-12 content content-full content-left">
                        <div className="heading center"><h1>Забыли пароль?</h1></div>
                        <div className="login">
                            <form onSubmit={onSubmit} method="post" id="lostPasswordForm">
                                <div className="form-part">
                                    <label>Email</label>
                                    <input type="text" {...register('email')} name="email" id="frm-lostPasswordForm-email" />
                                </div>
                                <input type="submit" name="getPassword" value="Получить новый пароль" className="btn btn-secondary" />
                            </form>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    )
}

export default LostPasswordPage;
