import { ReactElement, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { getOrderById } from '../../api/LayoutApi';
import { useStore } from '../../store/store';
import { OrderDetailDto } from '../../types/ResponseTypes';
import OrderItemElement from './OrderItemElement/OrderItemElement';

function OrderDetailPage(): ReactElement {
    const { userStore } = useStore();
    const { orderId } = useParams();
    const [order, setOrder] = useState<OrderDetailDto>();

    useEffect(() => {
        const getData = async () => {
            const response = await getOrderById(orderId as string, userStore.getJWT());
            setOrder(response.data);
        }
        getData();
    }, []);

    function getTotalPriceWithoutDelivery(): number {
        if (!order || !order.total_price_with_tax || (!order.delivery_cost && order.delivery_cost !== 0)) {
            return 0;
        }
        
        return order?.total_price_with_tax - order?.delivery_cost
    }

    return (
        <div className='container layout'>
            <main className='row'>
                <div className='col-sm-12 content content-full content-left'>
                    <div className='heading'>
                        <h1>Детали заказа {order && order.id}</h1>
                    </div>
                    <div className='orderdetail'>
                        <div className='data cfx'>
                            <p>
                                Номер заказа: {order && order.id}<br />
                                Статус заказа: {order && order.order_status.name} <br />
                                Телефон: {order && order.phone}<br />
                                Email: {order && order.email}
                            </p>
                            <div className='l'>
                                <h2>Ваши данные:</h2>
                                Имя: {order && order.payment_name}<br />
                                Улица: {order && order.payment_street}<br />
                                Номер дома: {order && order.payment_building_number}<br />
                                Город: {order && order.payment_city}<br />
                                Почтовый индекс: {order && order.payment_postcode}<br />
                                Страна: {order && order.payment_country && order.payment_country.name}
                            </div>
                            {
                                order?.delivery_name &&
                                <div className="l">
                                    <h2>Информация о доставке:</h2>
                                    Имя: {order && order.delivery_name}<br />
                                    Улица: {order && order.delivery_street}<br />
                                    Номер дома: {order && order.delivery_building_number}<br />
                                    Город: {order && order.delivery_city}<br />
                                    Почтовый индекс: {order && order.delivery_postcode}<br />
                                    Страна: {order && order.delivery_country && order.delivery_country.name}
                                </div>
                            }
                        </div>
                        <div className='items'>
                            <h2>Товары:</h2>
                            <table>
                                <tbody>
                                    <tr>
                                        <th className='left'>Товар</th>
                                        <th className='center'>НДС</th>
                                        <th className='center'>Стоимость за шт.</th>
                                        <th className='center'>Количество</th>
                                        <th className='center'>Итого</th>
                                    </tr>
                                    {
                                        order && order.order_items.map((item) => <OrderItemElement key={order.id} order={order} item={item} />)
                                    }
                                </tbody>
                            </table>
                        </div>
                        <p>
                            <strong>Стоимость товара с НДС: {getTotalPriceWithoutDelivery().toFixed(2)} P</strong>
                            <br />
                            <br />
                            Доставка: {order && order.delivery_method?.name} - {order && order.delivery_cost} P
                            <br />
                            Оплата: {order && order.payment_method?.name}  - {order && order.sum_paid} P
                            <br />
                            <strong>Стоимость доставки: {order && order.delivery_cost} P</strong>
                            <br />
                            <br />
                            Использовано бонусов: {order && (order.bonus_to_spend || 0)} P
                            <br />
                            <strong className='total'>Итого: {order && (order.total_price_with_tax - (
                                order.bonus_to_spend == null ? 0 : order.bonus_to_spend
                            )).toFixed(2)} P</strong>
                            <br />
                            <br />
                        </p>
                        <p>
                            <Link className='btn btn-primary' to='/my-account/orders'>
                                <span>Назад</span>
                            </Link>
                        </p>
                    </div>
                </div>
            </main>
        </div>
    );
}

export default OrderDetailPage;
