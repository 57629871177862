import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { searchPagePrefix } from '../../../constants/Constants';
import { useStore } from '../../../store/store';

function SearchBox() {
    const {filterStore} = useStore();
    const location = useLocation();
    const { register, handleSubmit, setValue } = useForm();
    const navigate = useNavigate();

    const keyFieldName = "key"

    useEffect(() => {
        const path = location.pathname;
        const value = path.startsWith(searchPagePrefix) ? decodeURI(path.substring(searchPagePrefix.length)) : ""
        setValue(keyFieldName, value)
    }, [location])

    const onSubmit = (data: any) => {
        if (data.key) {
            filterStore.doSearch(data.key, navigate);
        }
    }


    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <input type="text" placeholder="Поиск..." {...register(keyFieldName)} />
            <input type="submit" value={""} />
        </form>
    )
}

export default SearchBox;
