import { ReactElement } from 'react';
import FlashAlert from '../FlashAlert/FlashAlert';
import { FieldErrors } from 'react-hook-form';


type FromHeaderErrorsProps = {
    errors: FieldErrors<{[x: string]: any}>,
    clearErrors: (x: string) => void
}


function FromHeaderErrors({errors, clearErrors}: FromHeaderErrorsProps): ReactElement<FromHeaderErrorsProps> {
    function closeError(key: any) {
        clearErrors(key);
    }

    function getPlainErrors() {
        const list = Object();

        function getChildErrors(errorObj: FieldErrors<{[x: string]: any}>, keyPrefix: string) {
            (Object.keys(errorObj) as (keyof typeof errorObj)[]).forEach((key) => {
                const stringKey = key as string
                if (errorObj?.[stringKey] && errorObj?.[stringKey]!.message && typeof errorObj?.[stringKey]!.message == "string") {
                    list[`${keyPrefix}${key}`] = errorObj?.[stringKey]!.message as string
                } else {
                    getChildErrors(errorObj?.[stringKey] as FieldErrors<{[x: string]: any}>, `${keyPrefix}${key}.`)
                }
            })
        }

        getChildErrors(errors, "")
        return list
    }

    const plaineErrors = getPlainErrors()

    return (
        <>
            {(Object.keys(plaineErrors) as (keyof typeof plaineErrors)[]).map((key) => {
                return <FlashAlert key={plaineErrors[key]} message={plaineErrors[key]} close={() => closeError(key)}/>
            })}
        </>
    );
}

export default FromHeaderErrors;
