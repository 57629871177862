import { observer } from 'mobx-react-lite';
import { ReactElement, useEffect, useState } from 'react';
import { countProductsOnPage } from '../../constants/Constants';
import { useStore } from '../../store/store';

type PaginationProps = {
    page: number,
    setPage: Function,
}

const Pagination = observer(({page, setPage}: PaginationProps) => {
    const {productStore} = useStore();
    const [countPages, setCountPages] = useState<number>(0);
    useEffect(() => {
        setCountPages(Math.ceil(productStore.countProducts/countProductsOnPage));
    }, [productStore.countProducts]);
    

    function toFirst() {
        if (page !== 0) {
            setPage(0);
        }
    }

    function toPrev() {
        if (page !== 0) {
            setPage((value: any) => value-1);
        }
    }

    function toNext() {
        if (page !== countPages-1) {
            setPage((value: any) => value+1);
        }
    }

    function toLast() {
        if (page !== countPages-1) {
            setPage(countPages-1);
        }
    }

    return (
        <div className='products-list-loadmore'>
            <div id='snippet--productPager'>   
                <div className='pager'>
                    <ul className='reset'>
                        <li className={page === 0 ? 'disabled' : ''}><a onClick={toFirst}>« Первая</a></li>
                        <li className={page === 0 ? 'disabled' : ''}><a onClick={toPrev}>« Предыдущая</a></li>
                        <li className='active'><a><strong>{page + 1}</strong> / {countPages}</a></li>
                        <li className={page === countPages-1 ? 'disabled' : ''}><a onClick={toNext}>Дальше »</a></li>
                        <li className={page === countPages-1 ? 'disabled' : ''}><a onClick={toLast}>Последняя »</a></li>
                    </ul>
                </div>
            </div>
        </div>
    );
});

export default Pagination;