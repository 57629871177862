import { Link } from 'react-router-dom';
import OrderPipe from '../OrderPipe/OrderPipe';

const OrderCompletePage = () => {
    return (
        <div className='container layout'>
            <main className='row'>
                <div className='col-sm-12 content content-full content-left'>
                    <div className="alert alert-success">
                        Заказ был успешно сохранен.
                    </div>
                    <div className='cart-heading'>
                        <OrderPipe />
                    </div>
                    <div className='cart'>
                        <div className='recap'>
                            <div className='items'>
                                <p>Благодарим вас за совершенный заказ. Как только ваш заказ будет подтверждён, вы сможете оплатить его в разделе "Мой аккаунт". Мы пришлем вам письмо с подтверждением в течение нескольких минут.</p>
                            </div>
                            <div className='navigation cfx'>
                                <div className='l'>
                                    <Link className='inline-btn btn btn-secondary' to='/'>
                                        <span>◀ Назад к магазину</span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
};

export default OrderCompletePage;
