import { ReactElement, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getProductById, getProductsInGroup } from '../../api/LayoutApi';
import ProductDetail from './ProductDetail/ProductDetail';
import ProductImage from './ProductImage/ProductImage';
import ProductCartBox from './ProductCartBox/ProductCartBox';
import { Product } from '../../types/ResponseTypes';
import ProductImageGallery from './ProductImageGallery/ProductImageGallery';
import FlashAlert from '../FlashAlert/FlashAlert';

function ProductPage(): ReactElement {
  const [product, setProduct] = useState<Product>();
  const [variation, setVariation] = useState<Product>();
  const [productsInGroup, setProductsInGroup] = useState<Array<Product>>([]);
  const [errorMessages, setErrorMessages] = useState<Array<string>>([]);
  const { productId } = useParams();

  useEffect(() => {
    const getData = async () => {
      const openedProduct = (await getProductById(productId as string)).data;
      setProduct(openedProduct);
    };
    getData();
  }, []);

  useEffect(() => {
    const getData = async () => {
      if (product && product.group_by_id_1c) {
        const productList = (await getProductsInGroup(product.group_by_id_1c))
          .data;
        setProductsInGroup(productList);

        const activeVariation = productList.find(
          (v: Product) => v.id == product.id
        );
        setVariation(activeVariation);
      } else {
        setVariation(product);
      }
    };
    getData();
  }, [product]);

  const onVariationChange = (p: Product) => {
    setVariation(p);
    setProduct(p);
  };

  function addError(message: string) {
    if (errorMessages.some((error) => error === message)) {
      return;
    }

    const tmp = JSON.parse(JSON.stringify(errorMessages));
    tmp.push(message);
    setErrorMessages(tmp);
  }

  function closeErrorMessage(message: string) {
    setErrorMessages(
      JSON.parse(JSON.stringify(errorMessages.filter((m) => m !== message)))
    );
  }

  return (
    <div className='container layout product-page'>
      <div className='row'>
        {errorMessages &&
          errorMessages.length > 0 &&
          errorMessages.map((message) => (
            <FlashAlert
              key={message}
              message={message}
              close={() => closeErrorMessage(message)}
            />
          ))}
        <aside className='col-sm-2'></aside>
        <main className='col-sm-10'>
          <div className='product-detail'>
            <ProductImage product={product} variation={variation} />
            <ProductDetail product={product} />
          </div>
        </main>
      </div>
    </div>
  );
}

export default ProductPage;
