import { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { useStore } from '../../../store/store';

function CartNavigation(): ReactElement {
    const { userStore, cartStore } = useStore();

    return (
        <div className='navigation cfx'>
            <div className='l'>
                <Link to='/' className='inline-btn btn btn-secondary'>
                    <span>◀ Назад к магазину</span>
                </Link>
            </div>
            {
                userStore.isLogged && cartStore.cartItems.length > 0 && cartStore.checkIsAllItemsCanBeOrdered() &&
                <div className='r'>
                    <Link className='inline-btn btn btn-secondary' to='/cart/invoice-data'>
                        <span>Данные заказа ▶</span>
                    </Link>
                </div>
            }
        </div>
    );
}

export default CartNavigation;
