import { runInAction, makeAutoObservable } from 'mobx';
import { getCustomisationElements, getJerseyOptions, getProductsInGroup } from '../../api/LayoutApi';
import { CUSTOMISATION_JERSEY_ELEMENTS } from '../../constants/Constants';
import { CartItem, CustomisationElement, Product } from '../../types/ResponseTypes';
import UserStore from '../UserStore/UserStore';

class JerseyConstructorStore {
    constructor(userStore: UserStore) {
        makeAutoObservable(this);
        this.userStore = userStore;
        this.customisationElements = [];
        this.jerseyOptions = [];
        this.sizeAndColorOptions = new Map();
    }

    userStore: UserStore;
    customisationElements: Array<CustomisationElement>;
    jerseyOptions: Array<Product>;
    sizeAndColorOptions: Map<string, Array<Product>>

    async loadData() {
        const customisationElementsResp = await getCustomisationElements();
        
        if (customisationElementsResp && customisationElementsResp.data && customisationElementsResp.data.data) {
            const customisationElements = customisationElementsResp.data.data;
            runInAction(() => {
                this.customisationElements = customisationElements;
            });
        }

        const jerseyOptionsResp = await getJerseyOptions();
        if (jerseyOptionsResp && jerseyOptionsResp.data) {
            const onlySweaters = jerseyOptionsResp?.data.filter((product: Product) => product.common_name.includes('Свитер'));
            this.jerseyOptions = onlySweaters;
        }

        if (this.jerseyOptions.length > 0) {
            const ps = this.jerseyOptions.map((product) => getProductsInGroup(product.group_by_id_1c));
            const settledResp = await Promise.allSettled(ps);

            settledResp.forEach((resp) => {
                if (resp.status === 'fulfilled') {
                    const options: Array<Product> = resp.value.data.map((option: Product) => {
                        const newName = option.name.matchAll(/\((.*?)\)/g).next().value;
                        return {...option, name: newName ? newName[1] : option.name}
                    }).filter((option: any) => option.availability > 0);

                    if (options.length > 0) {
                        this.sizeAndColorOptions.set(options[0]?.group_by_id_1c, options);
                    }
                }
            })
        }
    }

    getCustomisationPriceByName(customisationName: string) {
        const element = this.customisationElements.find((element) => element.attributes.field_name === customisationName);
        return element?.attributes?.product?.data?.attributes?.price || 0;
    }

    calculatePriceByOne(cartItem: CartItem) {
        let price = 0;

        price += cartItem.product?.price || 0;
        price += this.getCustomisationPriceByName(CUSTOMISATION_JERSEY_ELEMENTS.number) * (cartItem.customisationref?.number?.toString().length || 0);
        price += this.getCustomisationPriceByName(CUSTOMISATION_JERSEY_ELEMENTS.name) * (cartItem.customisationref?.text?.length || 0);
        price += this.getCustomisationPriceByName(CUSTOMISATION_JERSEY_ELEMENTS.sleevesNumber) * (cartItem.customisationref?.number?.toString().length || 0) * 2;
        price += cartItem.customisationref?.captain ? this.getCustomisationPriceByName(CUSTOMISATION_JERSEY_ELEMENTS.captain) : 0;
        price += cartItem.customisationref?.alt_captain ? this.getCustomisationPriceByName(CUSTOMISATION_JERSEY_ELEMENTS.altCaptain) : 0;
        price += cartItem.customisationref?.memorial_ribbon ? this.getCustomisationPriceByName(CUSTOMISATION_JERSEY_ELEMENTS.memorialRibbon) : 0;

        return price;
    }
}

export default JerseyConstructorStore;
