import { ReactElement } from "react";
import { Product } from "../../types/ResponseTypes";
import ProductListItem from "./ProductListItem/ProductListItem";

type ProductListProps = {
    products: Array<Product>,
    header?: string
}

function CommonProductList({ header, products }: ProductListProps): ReactElement {
    return (
        <>
            <div className='row heading-row'>
                <div className='col-sm-6 heading'>
                    <h2>{header}</h2>
                </div>
            </div>
            <div className='row products-row'>
                { products.map((product) => <ProductListItem key={product.id} product={product} isCommon={true} />) }
            </div>
        </>
    );
}

export default CommonProductList;
