import { ReactElement, ReactNode, useState } from 'react';
import styles from './Tooltip.module.scss';

type TooltipProps = {
  text: string | ReactNode,
  children: ReactNode,
}

const Tooltip = ({ text, children }: TooltipProps): ReactElement => {
  const [isTooltipVisible, setTooltipVisible] = useState(false);

  const handleMouseEnter = () => {
    setTooltipVisible(true);
  };

  const handleMouseLeave = () => {
    setTooltipVisible(false);
  };

  return (
    <div className={styles.container}>
      <div
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {children}
      </div>
      {isTooltipVisible && <div className={styles.tooltip}>{text}</div>}
    </div>
  );
};

export default Tooltip;
