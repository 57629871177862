import { ReactElement, ReactNode, useEffect, useState } from 'react';
import { getUrlToImage } from '../../api/ApiHelper';
import { getBanners } from '../../api/LayoutApi';
import { BannerType } from '../../types/ResponseTypes';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'

function Banner(): ReactElement {
    const [banners, setBanners] = useState<Array<BannerType>>([]);

    useEffect(() => {
        const getData = async () => {
            const resp = await getBanners();
            setBanners(resp.data.data);
        }
        getData();
    }, []);

    // item is ReactNode here, however, we cannot access its .props if strictly typed
    function bannerClick(index: number, item: any) {
        if(item?.props && item?.props['custom-url']) {
            window.location.href = item?.props['custom-url'];
        }
    }

    return (
        <div className='row heading-row'>
            <div className='col-sm-12 heading'>
                {
                    banners.length > 0 &&
                    <Carousel
                        showArrows={true}
                        showThumbs={false}
                        infiniteLoop={true}
                        autoPlay={true}
                        interval={5000}
                        showStatus={false}
                        onClickItem={bannerClick}>
                        {
                            banners.map((banner: any, index: number) => (
                                <div key={`banner${index}`} custom-url={banner.attributes.url}>
                                    <img src={getUrlToImage(banner.attributes.image.data.attributes.url)} />
                                </div>
                            ))
                        }
                    </Carousel>
                }
            </div>
        </div>
    );
}

export default Banner;
