import { runInAction, makeAutoObservable } from 'mobx'
import { getBonusesBalance, getBonusesPrecalc, getBonusSettings } from '../../api/LayoutApi';
import UserStore from '../UserStore/UserStore';

class BonusesStore {
    constructor(userStore: UserStore) {
        makeAutoObservable(this);
        this.userStore = userStore;
        this.currentBonusBalance = null;
        this.isUseBonuses = false;
        this.bonusPrecalc = 0;
        this.countUsedBonuses = 0;
        this.allowBonusAdding = false;
        this.allowBonusPayments = false;
        this.isRegistered = true;
    }

    userStore: UserStore;
    currentBonusBalance: number | null;
    isUseBonuses: boolean;
    bonusPrecalc: number;
    countUsedBonuses: number;
    allowBonusAdding: boolean;
    allowBonusPayments: boolean;
    isRegistered: boolean;

    async loadBonusInfo() {
        if (this.userStore.isLogged) {
            try {
            const balanceResp = await getBonusesBalance(this.userStore.getJWT());
            const currentBonusBalance = balanceResp.data;

            runInAction(() => {
                this.currentBonusBalance = currentBonusBalance;
            });

            } catch (err: any) {
                this.currentBonusBalance = 0;
                this.isRegistered = false;
            }
        } else {
            this.currentBonusBalance = null;
        }
        const resp = await getBonusSettings();
        this.allowBonusAdding = resp.data.data.attributes.allow_bonus_adding || false;
        this.allowBonusPayments = resp.data.data.attributes.allow_bonus_payments || false;
    }

    async updateBonusPrecalc() {
        if (this.userStore.isLogged) {
            const precalcResp = await getBonusesPrecalc(this.userStore.getJWT());
            this.bonusPrecalc = precalcResp.data;
        }
    }

    getBonusPrecalc() {
        return this.bonusPrecalc;
    }
}

export default BonusesStore;
