import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useStore } from '../../../store/store';
import { Product } from '../../../types/ResponseTypes';
import Tooltip from '../../Tooltip/Tooltip';
import infoIcon from '../../../assets/info-icon.svg';

type JerseyLeftBlockProps = {
    jerseyOptions: Array<Product>,
    setName: Function,
    setNumber: Function,
    setIsWhite: Function,
    price: number,
    setSelectedJersey: Function,
    addToCart: Function
}

const JerseyLeftBlock = observer(({jerseyOptions, setName, setNumber, setIsWhite, setSelectedJersey, addToCart, price}: JerseyLeftBlockProps) => {
    const [sizeAndColorOptions, setSizeAndColorOptions] = useState<Array<Product>>([]);
    const { jerseyConstructorStore } = useStore();
    const [innerNumber, setInnerNumber] = useState<string>('');
    const [innerName, setInnerName] = useState<string>('');

    useEffect(() => {
        if (jerseyOptions.length > 0 && jerseyOptions[0].group_by_id_1c) {
            changeJerseyOption(jerseyOptions[0].group_by_id_1c);
        }
    }, [jerseyConstructorStore.sizeAndColorOptions.size]);

    useEffect(() => {
        if (sizeAndColorOptions.length > 0 && sizeAndColorOptions[0].id) {
            changeColorOption(sizeAndColorOptions[0].id.toString());
        }
    }, [sizeAndColorOptions]);

    function changeColorOption(optionId: string) {
        const selectedOption = sizeAndColorOptions.find((option) => option.id === Number(optionId));
        const isWhite = selectedOption?.name.includes('Белый');
        
        setSelectedJersey(selectedOption);
        setIsWhite(isWhite);
    }

    async function changeJerseyOption(id_by_1c: string) {
        const options = jerseyConstructorStore.sizeAndColorOptions.get(id_by_1c) || [];
        setSizeAndColorOptions(options);
    }

    function validateAndSetJerseyNumber(number: string) {
        if (!number || /^\d+$/.test(number)) {
            setInnerNumber(number);
            setNumber(number);
        }
    }

    function validateAndSetJerseyName(name: string) {
        if (!name || /^[\p{L}\s]+$/u.test(name)) {
            setInnerName(name);
            setName(name);
        }
    }

    return (
        <>
            <div className='row'>
                <div className='col-sm-6'>
                    <div className='custom-jersey-box'>
                        <label>Вариант</label>
                        <select className='changeVariantSB' onChange={(e) => changeJerseyOption(e.target.value)}>
                            {
                                jerseyOptions.map((option) => <option key={option.id} value={option.group_by_id_1c}>{option.common_name}</option>)
                            }
                        </select>
                    </div>
                </div>
                <div className='col-sm-6'>
                    <div className='custom-jersey-box'>
                        <label>Размер</label>
                        <select className='changeVariantSB' onChange={(e) => changeColorOption(e.target.value)}>
                            {
                                sizeAndColorOptions.map((option) => <option key={option.id} value={option.id}>{option.name}</option>)
                            }
                        </select>
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='col-sm-6'>
                    <div className='custom-jersey-box'>
                        <label>количество</label>
                        <select className='changeVariantSB'>
                            <option>1 шт</option>
                        </select>
                    </div>
                </div>
                <div className='col-sm-6'>
                    <div className='custom-jersey-box'>
                        <div style={{ display: 'flex', gap: '10px' }}>
                            <label>Номер и имя</label>
                            <Tooltip text={
                                <>
                                    <div>Номер должен состоять только из цифр.</div>
                                    <div>Имя должно состоять только из букв и пробелов.</div>
                                </>
                            }>
                                <img src={infoIcon} alt='' width={'14px'} height={'14px'}/>
                            </Tooltip>
                        </div>
                        <div className='custom-number-name-inputs'>
                            <input className='custom-jersey-number-input' value={innerNumber} maxLength={2} onChange={(e) => validateAndSetJerseyNumber(e.target.value)} />
                            <input className='custom-jersey-name-input' value={innerName} maxLength={10} onChange={(e) => validateAndSetJerseyName(e.target.value)} />
                        </div>
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='col-sm-6'>
                    <div className='custom-jersey-box'>
                        <div className='custom-jersey-prise'>{price} Р.</div>
                    </div>
                </div>
                <div className='col-sm-6'>
                    <div className='custom-jersey-box'>
                        <input type='button' className='btn btn-primary' value='В корзину' onClick={() => addToCart()} />
                    </div>
                </div>
            </div>
        </>
    );
});

export default JerseyLeftBlock;
