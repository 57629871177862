import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getUrlToImage } from '../../../api/ApiHelper';
import { getJerseyPrice } from '../../../api/LayoutApi';
import { thereAreOnly } from '../../../constants/Constants';
import { getCustomisationText } from '../../../helpers/helpers';
import { useStore } from '../../../store/store';
import { CartItem } from '../../../types/ResponseTypes';

type CartTableRowProps = {
    cartItem: CartItem,
    deleteProduct: Function
}

const CartTableRow = observer(({ cartItem, deleteProduct }: CartTableRowProps) => {
    const { cartStore } = useStore();
    const [amount, setAmount] = useState<number>(1);
    const [printedPrice, setPrintedPrice] = useState<number>(cartItem.product.price);

    useEffect(() => {
        const getData = async () => {
            if (cartItem.customisationref) {
                const data = await getJerseyPrice(cartItem.product.id, cartItem.customisationref);
                if (data) {
                    setPrintedPrice(data.data);
                }
            }
        }
        getData();
    }, []);

    useEffect(() => {
        setAmount(cartItem.amount);
    }, [cartItem]);

    function getLinkToProduct() {
        return `/product/${cartItem.product.uuid_1c}`;
    }

    function changeCount(e: React.ChangeEvent<HTMLInputElement>) {
        let newAmount = Number(e.target.value);
        if(newAmount == 0) {
            newAmount = 1;
        }

        const cartItemsExisting = cartStore.cartItems?.filter(
            (item:any) => item.product !== null && item.product.id === cartItem?.product?.id);

        if(cartItemsExisting && cartItemsExisting.length > 0) {
            const amountItemsAdded = cartItemsExisting.reduce((accumulator, currentValue) => {
                return accumulator + currentValue.amount
            }, 0);

            if((amountItemsAdded + newAmount - amount > cartItem?.product?.availability)) {
                alert(`Для ${cartItem.product.name} превышено количество. ${thereAreOnly(cartItem.product.availability)}`);
                const amountValue = amount && amount > 0 ? amount : 1;
                cartStore.changeAmount(cartItem, amountValue);
                e.target.value = amountValue.toString();
                return;
            } else {
                if (newAmount > 0) {
                    cartStore.changeAmount(cartItem, newAmount);
                    setAmount(newAmount);
                } else {
                    cartStore.changeAmount(cartItem, 1);
                    setAmount(1);
                    e.target.value = '1';
                }
            }
        }
    }

    return (
        <tr>
            <td className='left'>
                <Link to={getLinkToProduct()}>
                    <span className='image centerall'>
                        <img className='custom-cart-image' src={getUrlToImage(cartItem.picture_url)} alt={cartItem.product.name} title={cartItem.product.name} />
                    </span>
                </Link>
            </td>
            <td className='left'>
                <Link to={getLinkToProduct()}>
                    <strong>{cartItem.product.name}</strong>
                    {
                        cartItem.customisationref && <p>{getCustomisationText(cartItem)}</p>
                    }
                </Link>
                {
                    (cartItem.cantBeOrdered || cartItem.product.availability < cartItem.amount || !cartItem.product.publishedAt) &&
                    <p>Недостаточно товара на складе.</p>
                }
            </td>
            <td className='center'>{cartItem.tax.percent_value}%</td>
            <td className='center'>{printedPrice} P</td>
            <td className='center' >
                <input type='text' name='recount_34081' size={3} id='frm-recountForm-recount_34081' onChange={(e) => changeCount(e)} defaultValue={cartItem.amount} className='center' />
            </td>
            <td className='center'>{(cartItem.position_price || (cartItem.product.price * cartItem.amount)).toFixed(2)} P</td>
            <td className='center'>
                <input type='button' className='custom-delete-product-cart' onClick={() => deleteProduct(cartItem)} value='удалить' />
            </td>
        </tr>
    );
});

export default CartTableRow;
