import { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { getUrlToImage } from '../../api/ApiHelper';
import { BannerType } from '../../types/ResponseTypes';

type PopupProps = {
    popup: BannerType,
    closePopup: Function
}

function Popup({popup, closePopup}: PopupProps): ReactElement {
    return (
        <div className='custom-popup-container'>
            <div className='custom-popup-body'>
                <Link to={popup.attributes.url}>
                    <img src={getUrlToImage(popup.attributes.image.data.attributes.url)} />
                </Link>
                <button className='custom-btn-popup' onClick={() => closePopup()}>Close X</button>
            </div>
        </div>
    )
}

export default Popup;
