import { useEffect } from 'react';
import CartTable from './CartTable/CartTable';
import { useStore } from '../../store/store';
import RecountButton from './RecountButton/RecountButton';
import TotalPrice from './TotalPrice/TotalPrice';
import CartNavigation from './CartNavigation/CartNavigation';
import CartLinks from './CartLinks/CartLinks';
import OrderPipe from '../OrderPipe/OrderPipe';
import { observer } from 'mobx-react-lite';
import CartBonusBlock from './CartBonusBlock/CartBonusBlock';

const CartPage = observer(() => {
    const { cartStore } = useStore();

    useEffect(() => {
        const getData = async () => {
            await cartStore.loadCartInfo();
        }
        getData();
    }, []);

    return (
        <div className='container layout'>
		<main className='row'>
			<div className='col-sm-12 content content-full content-left'>
                <div className='cart-heading'>
                    <OrderPipe />
                </div>
				<div className='cart'>
                    <div className='cfx'>
                        <div className='content'>
                            <div className='items'>
                                <form action='/cart/content' method='post' id='recountForm'>
                                    {cartStore.cartItems.length > 0 && <CartTable /> }
                                    <RecountButton />
                                </form>
                            </div>
                            <CartBonusBlock />
                            <TotalPrice />
                            <CartLinks />
                            <CartNavigation />
                        </div>
                    </div>
			    </div>
		    </div>
        </main>
        </div>
    );
});

export default CartPage;
