import { ReactElement, useEffect, useState } from 'react';
import RedJerseyBack from '../../../assets/red_jersey_back.png';
import WhiteJerseyBack from '../../../assets/white_jersey_back.png';
import RedJerseyFront from '../../../assets/red_jersey_front.png';
import WhiteJerseyFront from '../../../assets/white_jersey_front.png';
import { whiteNumbers, redNumbers } from '../../../assets/numbers/index';

type JerseyPhotoBlockProps = {
    name: string,
    number: string,
    isWhiteJersey: boolean
}

function JerseyPhotoBlock({name, number, isWhiteJersey}: JerseyPhotoBlockProps): ReactElement {
    const [images, setImages] = useState<Array<any>>([]);
    const [jerseyBack, setJerseyBack] = useState<string>();
    const [jerseyFront, setJerseyFront] = useState<string>();

    useEffect(() => {
        changeNumbers();
    }, [number]);

    useEffect(() => {
        changeNumbers();
        setJerseyBack(isWhiteJersey ? WhiteJerseyBack : RedJerseyBack);
        setJerseyFront(isWhiteJersey ? WhiteJerseyFront : RedJerseyFront)
    }, [isWhiteJersey])

    function changeNumbers() {
        const numbersCollection = isWhiteJersey ? redNumbers : whiteNumbers;
        const imgArray = number.split('').map((char) => {
            if (!Number(char) && char !== '0') {
                return '';
            }
            const index = Number(char);
            return numbersCollection[index];
        });
        setImages(imgArray);
    }

    function getStyleForNameText() {
        return `custom-jersey-name ${isWhiteJersey ? 'custom-white-jersey' : 'custom-red-jersey'}`;
    }

    return (
        <div className='row custom-widget'>
            <div  className='col-sm-6'>
                <img src={jerseyFront} className='custom-jersey-image' />
            </div>
            <div className='col-sm-6'>
                <img src={jerseyBack} className='custom-jersey-image' />
                <div className='custom-jersey-preview-text'>
                    <div className={getStyleForNameText()}>{name}</div>
                    <div className='custom-jersey-number'>
                        {
                            images.map((img, index) => <img style={{height: '55%'}} key={img+index} src={img} />)
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default JerseyPhotoBlock;
