import { ReactElement, useCallback, useState } from 'react';
import ImageViewer from 'react-simple-image-viewer';
import { Product } from '../../../types/ResponseTypes';
import { getUrlToImage } from '../../../api/ApiHelper';

type ProductImageGalleryProps = {
    product?: Product
    variation?: Product
}

function ProductImageGallery({ product, variation }: ProductImageGalleryProps): ReactElement {
    const [currentImage, setCurrentImage] = useState(0);
    const [isViewerOpen, setIsViewerOpen] = useState(false);
    
    const images = variation?.image?.images?.map(e => {
        return {
            thumbnail: e.formats?.thumbnail?.url || e.url,
            full: e.url
        }
    }).filter(e => e.thumbnail || e.full);

    const showGalary = images && images.length > 0;
  
    const openImageViewer = useCallback((index: number) => {
      setCurrentImage(index);
      setIsViewerOpen(true);
    }, []);
  
    const closeImageViewer = () => {
      setCurrentImage(0);
      setIsViewerOpen(false);
    };

    return (
        <div className='product-gallery'>
            {showGalary && images.map((e, index) => (
                <img
                    src={ getUrlToImage(e.thumbnail) }
                    onClick={ () => openImageViewer(index) }
                    key={ index }
                    className='thumbnail'
                    alt={product?.common_name || product?.name}
                />
            ))}

            {showGalary && isViewerOpen && (
                <ImageViewer
                    src={ images.map(e => getUrlToImage(e.full)) }
                    currentIndex={ currentImage }
                    disableScroll={ true }
                    closeOnClickOutside={ true }
                    onClose={ closeImageViewer }
                    backgroundStyle={{
                        backgroundColor: "rgba(255,255,255,0.9)"
                    }}
                />
            )}            
        </div>
    );
}

export default ProductImageGallery;
