import { ReactElement } from 'react';
import { useFormContext } from 'react-hook-form';
import { useStore } from '../../../store/store';
import { Country } from '../../../types/ResponseTypes';

type UserInfoProp = {
    countries: Array<Country>,
    user?: any
}

function UserInfo({countries, user}: UserInfoProp): ReactElement {
    const { register } = useFormContext();
    const { userStore } = useStore();

    return (
        <fieldset>
            <legend>Ваши данные</legend>
            <p>
                <label>Имя</label>
                <input type='text' {...register('name')} id='frm-registrationForm-invoice_firstname' />
            </p>
            <p>
                <label>Фамилия</label>
                <input type='text' {...register('surname')} id='frm-registrationForm-invoice_lastname' />
            </p>
            <p>
                <label>Отчество</label>
                <input type='text' {...register('patronymic')} id='frm-registrationForm-invoice_middlename' />
            </p>
            <p>
                <label>Email</label>
                <input type='text' {...register('email')} id='frm-registrationForm-order_email' />
            </p>
            <p>
                <label>Телефон*</label>
                <input type='text' {...register('phone')} id='frm-registrationForm-phone' />
            </p>
            <p>
                <label>Дата рождения</label>
                <input type='date' {...register('birth')} id='frm-registrationForm-birth_date' />
            </p>
            {
                userStore.isLogged && (
                    <>
                        <p>
                            <label>Улица</label>
                            <input type="text" {...register('street')} id="frm-accountForm-invoice_street" />
                        </p>
                        <p>
                            <label>Номер дома</label>
                            <input type="text" {...register('building_number')} id="frm-accountForm-invoice_street_number" />
                        </p>
                        <p>
                            <label>Город</label>
                            <input type="text" {...register('city')} id="frm-accountForm-invoice_city" />
                        </p>
                        <p>
                            <label>Почтовый индекс</label>
                            <input type="text" {...register('postcode')} id="frm-accountForm-invoice_zip" />
                        </p>
                    </>
                )
            }
            <p>
                <label>Страна</label>
                <select {...register('country')} id='frm-registrationForm-id_invoice_country'>
                    {countries.map((country) => (
                        <option value={country.id} selected={user && user?.country && country.id === user?.country}>
                            {country.attributes.name}
                        </option>
                    ))}
                </select>
            </p>
            
            <p style={{color: '#cf072c'}}>* Будет использован в бонусной программе</p>
        </fieldset>
    );
}

export default UserInfo;
