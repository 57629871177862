import { ReactElement} from 'react';
import { useNavigate } from 'react-router-dom';
import { useStore } from '../../../store/store';
import { CartItem } from '../../../types/ResponseTypes';

function RecountButton(): ReactElement {
    const { cartStore } = useStore();
    const history = useNavigate();

    async function recountCart() {
        if (cartStore.cartItems) {
            const ps = cartStore.cartItems.map((item: CartItem) => cartStore.changeAmount(item, item.amount));

            await Promise.allSettled(ps);
            history(0);
        }
    }

    return (
        <div className='recount'>
            <input type='button' onClick={recountCart} name='recountCart' value='Пересчитать' className='btn btn-secondary' />
        </div>
    );
}

export default RecountButton;
