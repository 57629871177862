import React, { ReactElement, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { StaticPages } from '../../../types/ResponseTypes';
import { mapBaseAndBaseCollectionResponse } from '../../../mapping/ResponseMapping';
import { getContacts } from '../../../api/LayoutApi';
import { useStore } from '../../../store/store';
import { observer } from 'mobx-react-lite';
import SearchBox from '../SearchBox/SearchBox';

type NavbarProps = {
  menu: Array<StaticPages>;
};

const Navbar = observer(({ menu }: NavbarProps) => {
  const [contacts, setContacts] = useState<string>();
  const { userStore, bonusStore, cartStore } = useStore();

  function getReactLinkComponent(element: StaticPages): ReactElement<any, any> {
    return element.attributes.url?.startsWith('http') ? (
      <li key={element.attributes.url}>
        <a href={element.attributes.url} target='_blank'>
          {element.attributes.name}
        </a>
      </li>
    ) : (
      <li key={element.attributes.url}>
        <Link to={element.attributes.url || '/'}>
          {element.attributes.name}
        </Link>
      </li>
    );
  }

  useEffect(() => {
    const getData = async () => {
      const contactList = await getContacts();
      setContacts(
        mapBaseAndBaseCollectionResponse(contactList).attributes.value
      );
    };
    getData();
  }, []);

  function logout() {
    userStore.logout();
    cartStore.clear();
  }

  return (
    <div className='row top-row'>
      <div className='col-sm-5 box-left'>{contacts}</div>
      <div className='col-sm-7 box-right'>
        <ul>{menu?.map((el) => getReactLinkComponent(el))}</ul>
        <div className='login-searchbox'>
          <div className='box-searchbox'>
            <SearchBox />
          </div>
          {userStore.isLogged ? (
            <div className='user'>
              <Link to='/my-account'>
                МОЙ АККАУНТ &nbsp;
                {bonusStore.currentBonusBalance &&
                  `(${bonusStore.currentBonusBalance} бонусов)`}
              </Link>
              &nbsp;/&nbsp;
              <Link to='/' onClick={logout}>
                ВЫЙТИ
              </Link>
            </div>
          ) : (
            <div className='user'>
              <Link to='/login'>ВОЙТИ</Link>
              &nbsp;/&nbsp;
              <Link to='/registration'>РЕГИСТРАЦИЯ</Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
});

export default Navbar;
