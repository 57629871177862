import { useStore } from '../../../store/store';
import { observer } from 'mobx-react-lite';

const TotalPrice = observer(() => {
    const { cartStore, bonusStore } = useStore();

    return (
        <div className='price right'>
            <div className='totalprice'>Итого: {cartStore.getTotal() - bonusStore.countUsedBonuses} P</div>
        </div>
    )
})

export default TotalPrice;
