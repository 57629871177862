import { runInAction, makeAutoObservable } from 'mobx'
import { getCategoryProducts, getCountProducts, getNovelties, getTopWeek } from '../../api/LayoutApi';
import { Category, Product } from '../../types/ResponseTypes';
import FilterStore from '../FilterStore/FilterStore';

class ProductStore {
    constructor(filterStore: FilterStore) {
        makeAutoObservable(this);

        this.filterStore = filterStore;
        this.products = [];
        this.novelties = [];
        this.topWeek = [];
        this.countProducts = 0;
    }

    filterStore: FilterStore;
    products: Array<Product>;
    novelties: Array<Product>;
    topWeek: Array<Product>;
    countProducts: number;

    async loadProducts(page: number) {
        const allFilters = this.filterStore.getAllFilters();
        this.products = await (await getCategoryProducts(allFilters, this.filterStore.sort, page)).data;
    }

    async loadCountProductsWithFilters() {
        const allFilters = this.filterStore.getAllFilters();
        this.countProducts = await (await getCountProducts(allFilters)).data;
    }

    async loadNovelties() {
        const novelties = (await getNovelties()).data;
        runInAction(() => {
            this.novelties = novelties;
        });
    }

    async loadTopWeek() {
        const topWeek = (await getTopWeek()).data;
        runInAction(() => {
            this.topWeek = topWeek;
        });
    }
}

export default ProductStore;
