import { ReactElement } from "react";
import { getUrlToImage } from "../../../api/ApiHelper";
import { Product } from "../../../types/ResponseTypes";

type ImageItemProps = {
    product: Product,
    isCommon: boolean
}

function ImageItem({ product, isCommon }: ImageItemProps): ReactElement {
    const image = product.image?.main_image

    return (
        <div className={isCommon ? 'product-image' : 'product-photo'}>
            <img src={getUrlToImage(image?.formats?.small ? image?.formats.small.url : image?.url)} alt={product.common_name} />
        </div>
    );
}

export default ImageItem;
