import { ReactElement, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getStaticPageById } from '../../api/LayoutApi';
import { StaticPageType, StaticPages } from '../../types/ResponseTypes';

type StaticPageProps = {
    menu: Array<StaticPages>
}

function StaticPage({ menu }: StaticPageProps): ReactElement {
    const [staticPage, setStaticPage] = useState<StaticPageType>();
    let location = useLocation();
    useEffect(() => {
        const currentStaticPage = menu.find((element) => element.attributes.url === location.pathname);
        if (!currentStaticPage) {
            return;
        }

        const getData = async () => {
            const resp = await getStaticPageById(currentStaticPage.id);
            setStaticPage(resp.data);
        }
        getData();
    }, [menu, location]);

    return (
        <div className='container layout'>
			<main className='row'>
                <div className='col-sm-12 content content-full'>
                    <div className='heading'>
                        <h1>{staticPage && staticPage.data.attributes.name}</h1>
                    </div>
                    <div className='static'>
                    {
                        staticPage &&
                        <div dangerouslySetInnerHTML={{ __html: staticPage.data.attributes.text}}  />
                    }
                    </div>
                </div>
			</main>
	    </div>
    )
}

export default StaticPage;
