import { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { Product } from '../../../types/ResponseTypes';

type PathToProductProps = {
    product?: Product
}

function PathToProduct({ product }: PathToProductProps): ReactElement {
    const subCategoryName = product?.subcategory?.display_name || product?.subcategory?.name

    return (
        <div className='breadcrumbs'>
            <Link to='/' title='Главная страница'>Главная страница</Link>
            <span className='del'>{' > '}</span>
            {
                product?.category?.name &&
                <Link
                    to={product.category?.url || ''}
                    title={product.category?.name}
                    >
                    {product.category?.name}
                </Link>
            }
            {
                product?.category?.name && <span className='del'>{' > '}</span>
            }
            {
                subCategoryName &&
                <Link
                    to={product.subcategory?.url || ''}
                    title={subCategoryName}
                    >
                    {subCategoryName}
                </Link>
            }
            {
                subCategoryName && <span className='del'>{' > '}</span>
            }
            {product?.common_name || product?.name}
        </div>
    );
}

export default PathToProduct;
