import { ReactElement } from "react";
import { Link } from "react-router-dom";
import Sticker from "../Sticker/Sticker";
import ImageItem from "../ImageItem/ImageItem";
import ProductBody from "../ProductBody/ProductBody";
import { Product } from "../../../types/ResponseTypes";

type ProductListItemProps = {
    product: Product,
    isCommon: boolean
}

function ProductListItem({ product, isCommon }: ProductListItemProps): ReactElement {
    function getUrl() {
        return `/product/${product.uuid_1c}`;
    }
    return (
        <Link to={getUrl()} target='_blank' title={product.common_name} className='product'>
            <div className="product-inner">
                {(product.novelty || product.discount || product.partial_discount) && <Sticker product={product} />}
                <ImageItem product={product} isCommon={isCommon} />
                <ProductBody product={product} isCommon={isCommon} />
            </div>
        </Link>
    );
}

export default ProductListItem;
