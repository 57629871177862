import { observer } from 'mobx-react-lite';
import { DLS_MAX_BONUS_WITHDRAW_PERCENT } from '../../../constants/Constants';
import { useStore } from '../../../store/store';

const CartBonusBlock = observer(() => {
    const {cartStore, bonusStore} = useStore();

    function getCanBeUsedBonuses() {
        let sum = 0;
        cartStore.cartItems.forEach((item) => {
            if (item.product.price >= item.product.default_price && item.product.discount !== true) {
                sum += item?.position_price || item.product.price * item.amount;
            }
        });

        return Math.min(Number((sum * DLS_MAX_BONUS_WITHDRAW_PERCENT / 100).toFixed(2)), bonusStore.currentBonusBalance || 0);
    }

    function onChangeCountUsedBonuses(e: React.ChangeEvent<HTMLInputElement>) {
        const newAmount = Number(e.target.value) || 0;

        if (newAmount > getCanBeUsedBonuses()) {
            alert(`Списать можно только ${getCanBeUsedBonuses()} бонусов`);
        } else if (newAmount < 0) {
            alert(`Количество списанных бонусов не может быть отрицательным`);
        } else {
            bonusStore.countUsedBonuses = newAmount;
            if (newAmount > 0) {
                bonusStore.isUseBonuses = true;
            } else {
                bonusStore.isUseBonuses = false;
            }
        }
    }

    return (
        <>
            <div className='coupon'>
                {
                    bonusStore.allowBonusAdding &&
                    !bonusStore.isUseBonuses &&
                    <h5>За покупку вы получите: {bonusStore.getBonusPrecalc()} бонусов</h5>
                }
                {
                    !!bonusStore.isUseBonuses && 
                    <span className='used'>
                        Использовано бонусов: <strong>{bonusStore.countUsedBonuses}</strong>
                    </span>
                }
            </div>
            {
                bonusStore.allowBonusPayments &&
                <div className='coupon'>
                    <h4>Ваши бонусы: {!!bonusStore.currentBonusBalance && bonusStore.currentBonusBalance }</h4>
                    <h5>Количество бонусов, которыми вы можете оплатить эту покупку: {getCanBeUsedBonuses()}</h5>
                    <input
                        type='number'
                        name='bonus'
                        min='0'
                        max={getCanBeUsedBonuses()}
                        id='frm-dlsBonusForm-bonus'
                        defaultValue={bonusStore.countUsedBonuses}
                        onChange={(e) => onChangeCountUsedBonuses(e)}
                    />
                </div>
            }
        </>
    );
});

export default CartBonusBlock;
