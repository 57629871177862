import { useEffect, useState } from 'react';
import { runInAction } from 'mobx';
import { useStore, } from '../../store/store';
import { mapBaseAndBaseCollectionResponse } from '../../mapping/ResponseMapping';
import Pipe from '../OrderPipe/OrderPipe';
import { Link, useNavigate } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import FromHeaderErrors from '../FormHeaderErrors/FormHeaderErrors';
import { getCountries, getDeliveryPrice, getFreeDeliveryPromotion, updateProfile } from '../../api/LayoutApi';
import { Country } from '../../types/ResponseTypes';
import RussianPost from './RussianPost/RussianPost';
import Cdek from './Cdek/Cdek';
import Dpd from './Dpd/Dpd';
import { CDEK_PACKAGE_PARAMETERS } from '../../constants/Constants';


const InvoiceDataPage = () => {
    const { orderStore, userStore, cartStore } = useStore();
    const [countries, setCountries] = useState([]);
    const [delivery, setDelivery] = useState<boolean>(false);
    const [freeDeliveryValue, setFreeDeliveryValue] = useState<number | null>();
    const navigate = useNavigate();
    const methods = useForm();

    useEffect(() => {
        const getData = async () => {
            await orderStore.userStore.loadUserInfo()
            orderStore.loadCurrentOrder()

            methods.setValue('payment_name', orderStore.currentOrder.payment_name || orderStore.userStore.currentUser!.name)
            methods.setValue('payment_surname', orderStore.currentOrder.payment_surname || orderStore.userStore.currentUser!.surname)

            methods.setValue('email', orderStore.currentOrder.email || orderStore.userStore.currentUser!.email)
            methods.setValue('phone', orderStore.currentOrder.phone || orderStore.userStore.currentUser!.phone)
            
            methods.setValue('payment_street', orderStore.currentOrder.payment_street || orderStore.userStore.currentUser!.street)
            methods.setValue('payment_building_number', orderStore.currentOrder.payment_building_number || orderStore.userStore.currentUser!.building_number)
            methods.setValue('payment_flat_number', orderStore.currentOrder.payment_flat_number || '')
            methods.setValue('payment_city', orderStore.currentOrder.payment_city || orderStore.userStore.currentUser!.city)
            methods.setValue('payment_postcode', orderStore.currentOrder.payment_postcode || orderStore.userStore.currentUser!.postcode)
            methods.setValue('payment_country', orderStore.currentOrder.payment_country || orderStore.userStore.currentUser!.country)
            
            methods.setValue('notes', orderStore.currentOrder.notes)
            methods.setValue('newsletter', true)

            let delivery_name = orderStore.currentOrder.delivery_name;
            if (orderStore.currentOrder.delivery_method.id === 1 || orderStore.currentOrder.delivery_method.id === 2) {
                delivery_name = delivery_name || orderStore.currentOrder.payment_name || orderStore.userStore.currentUser!.name;
            }
            methods.setValue('delivery_filled', orderStore.currentOrder.delivery_filled);
            methods.setValue('delivery_name', delivery_name);
            methods.setValue('delivery_street', orderStore.currentOrder.delivery_street)
            methods.setValue('delivery_building_number', orderStore.currentOrder.delivery_building_number)
            methods.setValue('delivery_flat_number', orderStore.currentOrder.delivery_flat_number || '')
            methods.setValue('delivery_city', orderStore.currentOrder.delivery_city)
            methods.setValue('delivery_postcode', orderStore.currentOrder.delivery_postcode)
            methods.setValue('delivery_country', orderStore.currentOrder.delivery_country || orderStore.userStore.currentUser!.delivery_country)

            setDelivery(
                !!(orderStore.currentOrder.delivery_name || 
                orderStore.currentOrder.delivery_street || 
                orderStore.currentOrder.delivery_building_number || 
                orderStore.currentOrder.delivery_flat_number ||
                orderStore.currentOrder.delivery_city ||
                orderStore.currentOrder.delivery_postcode));
        }

        getData()
    }, [])

    useEffect(() => {
        const getData = async () => {
            const response = await getCountries();
            setCountries(mapBaseAndBaseCollectionResponse(response));   
        }

        getData();
    }, []);

    useEffect(() => {
        const getData = async () => {
            try {
                const response = await getFreeDeliveryPromotion(userStore.getJWT());
                if (response.data.data?.attributes?.sum) {
                    setFreeDeliveryValue(response.data.data.attributes.sum);
                } else {
                    setFreeDeliveryValue(null);
                }
            } catch(error: any) {
                setFreeDeliveryValue(null);
            }
        }

        getData();
    }, []);

    const onSubmit = async (data: any) => {
        orderStore.currentOrder.payment_name = data.payment_name;
        orderStore.currentOrder.payment_surname = data.payment_surname;
        orderStore.currentOrder.email = data.email;
        orderStore.currentOrder.phone = data.phone;
        orderStore.currentOrder.payment_street = data.payment_street;
        orderStore.currentOrder.payment_building_number = data.payment_building_number;
        orderStore.currentOrder.payment_flat_number = data.payment_flat_number;
        orderStore.currentOrder.payment_city = data.payment_city;
        orderStore.currentOrder.payment_postcode = data.payment_postcode;
        orderStore.currentOrder.payment_country = Number(data.payment_country);
        orderStore.currentOrder.notes = data.notes;
        orderStore.currentOrder.delivery_name = data.delivery_name;
        orderStore.currentOrder.delivery_street = data.delivery_street;
        orderStore.currentOrder.delivery_building_number = data.delivery_building_number;
        orderStore.currentOrder.delivery_flat_number = data.delivery_flat_number;
        orderStore.currentOrder.delivery_city = data.delivery_city;
        orderStore.currentOrder.delivery_postcode = data.delivery_postcode;
        orderStore.currentOrder.delivery_country = Number(data.delivery_country);
        
        
        orderStore.saveCurrentOrderToLocalStore();

        if (data.newsletter && !orderStore.userStore.currentUser!.newsletter_subscribed) {
            orderStore.userStore.currentUser!.newsletter_subscribed = true;
            updateProfile(orderStore.userStore.currentUser!, orderStore.userStore.getJWT());
        }

        navigate('/cart/delivery-payment');
    }

    return (
        <div className='container layout'>
            <main className='row'>
                <div className='col-sm-12 content content-full content-left'>
                    <FromHeaderErrors errors={methods.formState.errors} clearErrors={methods.clearErrors} />
                    <div className='cart-heading'>
                        <Pipe />
                    </div>
                    <div className='alert alert-warning'>
                        Пожалуйста, укажите адрес и данные человека, который будет принимать или забирать заказ. Если вы будете принимать заказ самостоятельно по адресу, указанному в «Моем аккаунте», оставьте заполненные поля без изменений.
                    </div>
                    <div className='cart'>
                        <div className='invoice'>
                            <div id='snippet--deliverySnippet'>
                                <FormProvider {...methods} >
                                    <div className='cfx'>
                                        <div className='l'>
                                            <form>
                                                <fieldset>
                                                    <legend>Ваши данные</legend>
                                                    <p>
                                                        <label htmlFor='frm-invoiceForm-invoice_firstname'>Имя</label> 
                                                        <input {...methods.register('payment_name', {required: 'Пожалуйста, введите имя'})} />
                                                    </p>
                                                    <p>
                                                        <label htmlFor='frm-invoiceForm-invoice_lastname'>Фамилия</label> 
                                                        <input {...methods.register('payment_surname', {required: 'Пожалуйста, введите фамилию'})}  />
                                                    </p>
                                                    <p>
                                                        <label htmlFor='frm-invoiceForm-order_email'>Email</label> 
                                                        <input id='frm-invoiceForm-order_email' {...methods.register('email', {
                                                            required: 'Пожалуйста, введите Ваш email', 
                                                            pattern: {
                                                                value: /^(([^<>()[\]\.,;:\s@\']+(\.[^<>()[\]\.,;:\s@\']+)*)|(\'.+\'))@(([^<>()[\]\.,;:\s@\']+\.)+[^<>()[\]\.,;:\s@\']{2,})$/i, 
                                                                message: 'Пожалуйста, введите действительный email'
                                                        }})} />
                                                    </p>
                                                    <p>
                                                        <label htmlFor='frm-invoiceForm-order_phone'>Телефон</label> 
                                                        <input id='frm-invoiceForm-order_phone' {...methods.register('phone', {required: 'Пожалуйста, введите номер телефона'})} />
                                                    </p>
                                                    <p>
                                                        <label htmlFor='frm-invoiceForm-invoice_street'>Улица</label> 
                                                        <input {...methods.register('payment_street', {required: 'Пожалуйста, введите улицу'})} id='frm-invoiceForm-invoice_street' />
                                                    </p>
                                                    <p>
                                                        <label htmlFor='frm-invoiceForm-invoice_street_number'>Номер дома</label> 
                                                        <input id='frm-invoiceForm-invoice_street_number' {...methods.register('payment_building_number', {required: 'Пожалуйста, введите номер дома'})} />
                                                    </p>

                                                    <p>
                                                        <label htmlFor='frm-invoiceForm-invoice_room_number'>Номер квартиры</label> 
                                                        <input {...methods.register('payment_flat_number')} id='frm-invoiceForm-invoice.roomNumber' />
                                                    </p>

                                                    <p>
                                                        <label htmlFor='frm-invoiceForm-invoice_city'>Город</label> 
                                                        <input id='frm-invoiceForm-invoice_city' {...methods.register('payment_city', {required: 'Пожалуйста, введите город'})} />
                                                    </p>
                                                    <p>
                                                        <label htmlFor='frm-invoiceForm-invoice_zip'>Почтовый индекс</label> 
                                                        <input id='frm-invoiceForm-invoice_zip' {...methods.register('payment_postcode', {required: 'Пожалуйста, введите Почтовый индекс'})} />
                                                    </p>
                                                    <p>
                                                        <label htmlFor='frm-invoiceForm-id_invoice_country'>Страна</label> 
                                                        <select {...methods.register('payment_country')} id='frm-invoiceForm-id_invoice_country' defaultValue={methods.getValues('payment_country')}>
                                                            {countries.map((country: Country) => (
                                                                <option key={country.id} value={country.id}>
                                                                    {country.attributes.name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </p>
                                                </fieldset>
                                                <fieldset>
                                                    <p className='textarea'>
                                                        <label htmlFor='frm-invoiceForm-caption'>Комментарии к заказу:</label> 
                                                        <textarea {...methods.register('notes')} id='frm-invoiceForm-caption' />
                                                    </p>
                                                    <p className='checkbox'>
                                                        <label htmlFor='frm-invoiceForm-agreement'>
                                                            Нажимая на кнопку, вы соглашаетесь с <a href='/s10-o-sajjte'>условия магазина</a>
                                                        </label>
                                                    </p>
                                                    <p className='checkbox'>
                                                        <label htmlFor='frm-invoiceForm-newsletter'>
                                                            <input type='checkbox' {...methods.register('newsletter')} id='frm-invoiceForm-newsletter' />
                                                            Я согласен с подпиской на новости
                                                        </label>
                                                    </p>
                                                </fieldset>
                                            </form>
                                        </div>
                                    </div>
                                    <div className='navigation cfx'>
                                        <div className='l'>
                                            <Link className='inline-btn btn btn-secondary' to='/cart/delivery-payment'>
                                                <span>◀ Доставка и оплата</span>
                                            </Link>
                                        </div>
                                        <div className='r'>
                                            <span className='inline-btn'>
                                                <input type='button' onClick={methods.handleSubmit(onSubmit)} value='Доставка и оплата ▶' className='btn btn-secondary' />
                                            </span>
                                        </div>
                                    </div>
                                </FormProvider>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
}

export default InvoiceDataPage;
