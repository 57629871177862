import { runInAction, makeAutoObservable } from 'mobx'
import { getCart, addProductToCart, deleteProductFromCart, getJerseyPrice, saveCustomisation, updateProductAmount } from '../../api/LayoutApi';
import { taxId } from '../../constants/Constants';
import { compareCustomisations } from '../../helpers/helpers';
import { Cart, CartItem } from '../../types/ResponseTypes';
import BonusesStore from '../BonusesStore/BonusesStore';
import JerseyConstructorStore from '../JerseyConstructorStore/JerseyConstructorStore';
import UserStore from '../UserStore/UserStore';

class CartStore {
    constructor(userStore: UserStore, bonusStore: BonusesStore, jerseyStore: JerseyConstructorStore) {
        makeAutoObservable(this);
        this.userStore = userStore;
        this.bonusStore = bonusStore;
        this.jerseyStore = jerseyStore;
    }

    userStore: UserStore;
    bonusStore: BonusesStore;
    jerseyStore: JerseyConstructorStore;
    cartItems: Array<CartItem> = [];
    cartId: number = 0;
    isLoading: boolean = false;

    async loadCartInfo() {
        if (this.isLoading) {
            return;
        }

        this.isLoading = true;
        const localStorageItems = JSON.parse(window.localStorage.getItem('cartItems') || '[]');

        if (this.userStore.isLogged) {
            const back = (await getCart(this.userStore.getJWT()))?.data;
            runInAction(() => {
                this.cartItems = back?.cart_items || [];
            });

            const ps = localStorageItems.map((item: CartItem) => this.syncCartItem(item, back?.cart_items || []));
            await Promise.allSettled(ps);

            window.localStorage.setItem('cartItems', JSON.stringify(this.cartItems));
            this.cartId = back?.id || 0;
            if(this.cartItems?.length != 0) {
                this.bonusStore.updateBonusPrecalc();
            } else {
                this.bonusStore.bonusPrecalc = 0;
            }
        } else {
            runInAction(() => {
                this.cartItems = localStorageItems;
            });
            this.cartId = 0;
        }
        this.isLoading = false;
    }

    private async syncCartItem(localStorageItem: CartItem, backItems: Array<CartItem>) {
        const index = backItems.findIndex((cartItem) => cartItem.product.id === localStorageItem.product.id &&
            compareCustomisations(cartItem.customisationref, localStorageItem.customisationref));
        if (index === -1) {
            return this.addItem(localStorageItem);
        } else {
            if (backItems[index].amount !== localStorageItem.amount) {
                return this.changeAmount(backItems[index], localStorageItem.amount);
            }
        }
    }

    private async saveToServer(item: CartItem, postOrPut: Function, cartItemId?: number) {
        let data;
        if (item.customisationref && !item.customisationref.id) {
            const resp = await saveCustomisation(item.customisationref, this.userStore.getJWT());
            data = {
                data: {
                    cartItemId: cartItemId,
                    product: item.product.id,
                    amount: item.amount,
                    tax: taxId,
                    customisationref: resp?.data?.data?.id
                }
            }
        } else {
            data = {
                data: {
                    cartItemId: cartItemId,
                    product: item.product.id,
                    amount: item.amount,
                    tax: taxId
                }
            }
        }

        let response;
        try {
            response = await postOrPut(data, this.userStore.getJWT());

            const goodCartItem = this.cartItems.find((cartItem) => cartItem.product.id === item.product.id);
            if (goodCartItem) {
                goodCartItem.cantBeOrdered = false;
            }

            if(this.cartItems?.length != 0) {
                this.bonusStore.updateBonusPrecalc();
            } else {
                this.bonusStore.bonusPrecalc = 0;
            }
        } catch (e: any) {
            const error = e.response.data?.error;
            if (error && error.message) {
                const badCartItem = this.cartItems.find((cartItem) => cartItem.product.id === item.product.id);
                if (badCartItem) {
                    badCartItem.cantBeOrdered = true;
                }
            }
        } finally {
            return response || Promise.resolve();
        }
    }

    checkIsAllItemsCanBeOrdered() {
        return !this.cartItems.some((item) => !!item.cantBeOrdered || item.amount > item.product.availability || !item.product.publishedAt);
    }

    async addItem(item: CartItem) {
        if (item.customisationref && item.product.jersey_customisable) {
            const data = await getJerseyPrice(item.id, item.customisationref);
            if (data) {
                item.product.price = data.data;
            }
        }

        const index = this.getItemIndex(item);
        if (index >= 0) {
            const newAmount = this.cartItems[index].amount + item.amount;
            return this.changeAmount(item, newAmount);
        } else {
            this.cartItems.push(item);
            if (this.userStore.isLogged) {
                await this.saveToServer(item, addProductToCart, undefined);
            }
        }

        window.localStorage.setItem('cartItems', JSON.stringify(this.cartItems));
    }

    changeAmount(item: CartItem, amount: number) {
        const index = this.getItemIndex(item);
        let cartItemId = undefined;
        if (index >= 0) {
            const priceByOne = this.cartItems[index].product.jersey_customisable ?
                this.jerseyStore.calculatePriceByOne(item) :
                this.cartItems[index].product.price;
            this.cartItems[index].amount = amount;
            this.cartItems[index].position_price = Number(priceByOne) * amount;
            item.amount = amount;
            cartItemId = this.cartItems[index].id;
        }

        if (this.userStore.isLogged) {
            this.saveToServer(item, updateProductAmount, cartItemId);
        }

        window.localStorage.setItem('cartItems', JSON.stringify(this.cartItems));
    }

    deleteItem(deletedItem: CartItem) {
        this.cartItems = this.cartItems.filter((item) => {
            if (deletedItem.customisationref) {
                // pennant comparison
                if(deletedItem.customisationref.pennant_name || deletedItem.customisationref.pennant_number) {
                    const isNeedDelete = deletedItem.id === item?.id &&
                    deletedItem.customisationref.pennant_name === item?.customisationref?.pennant_name &&
                    deletedItem.customisationref.pennant_number === item?.customisationref?.pennant_number;
                    return !isNeedDelete;
                // jersey comparison
                } else {
                    const isNeedDelete = deletedItem.id === item?.id &&
                        deletedItem.customisationref.alt_captain === item?.customisationref?.alt_captain &&
                        deletedItem.customisationref.captain === item?.customisationref?.captain &&
                        deletedItem.customisationref.memorial_ribbon === item?.customisationref?.memorial_ribbon &&
                        deletedItem.customisationref.number === item?.customisationref?.number &&
                        deletedItem.customisationref.sleeves_number === item?.customisationref?.sleeves_number &&
                        deletedItem.customisationref.text === item?.customisationref?.text
                    return !isNeedDelete;
                }
            }

            return item.id !== deletedItem.id
        });

        window.localStorage.setItem('cartItems', JSON.stringify(this.cartItems));
        if (this.userStore.isLogged) {
            return deleteProductFromCart(deletedItem.id, this.userStore.getJWT());
        }

        return;
    }

    loadFromLocalStorage() {
        this.cartItems = JSON.parse(window.localStorage.getItem('cartItems') || '[]');
    }

    clearLocalStorage() {
        window.localStorage.removeItem('cartItems');
    }

    clear() {
        this.clearLocalStorage()
        this.cartItems = [];
    }

    getTotal() {
        let total = 0;
        this.cartItems.forEach((item) => {
            total += item.position_price ? item.position_price : item.amount * item.product.price;
        });
        return total;
    }

    getCountItems() {
        let countItems = 0;
        this.cartItems.forEach((item) => {
            countItems += item.amount;
        });
        return countItems;
    }

    getItemIndex(item: CartItem) {
        let index = -1;
        if(!item.customisationref) {
            index = this.cartItems.findIndex((i) => i.product.id === item.product.id && !i.customisationref);
        } else {
            if(item.customisationref.pennant_name || item.customisationref.pennant_number) {
                index = this.cartItems.findIndex(
                    (i) =>
                    i.product.id === item.product.id &&
                    i.customisationref?.pennant_name === item.customisationref?.pennant_name &&
                    i.customisationref?.pennant_number === item.customisationref?.pennant_number);
            } else {
                index = this.cartItems.findIndex(
                    (i) => i.product.id === item.product.id &&
                    i.customisationref?.alt_captain === item.customisationref?.alt_captain &&
                    i.customisationref?.captain === item.customisationref?.captain &&
                    i.customisationref?.memorial_ribbon === item.customisationref?.memorial_ribbon &&
                    i.customisationref?.number === item.customisationref?.number &&
                    i.customisationref?.sleeves_number === item.customisationref?.sleeves_number &&
                    i.customisationref?.text === item.customisationref?.text);
            }
        }

        return index;
    }
}

export default CartStore;
