import { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { useStore } from '../../../store/store';

function CartLinks(): ReactElement {
    const { userStore } = useStore();

    return (
        <>
        {
            !userStore.isLogged && 
            <p className='right'>
                Для завершения заказа Вам необходимо
                <Link to='/registration'> зарегистрироваться </Link> или 
                <Link to='/login'> войти</Link>.
            </p>
        }
        </>
    );
}

export default CartLinks;
