import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { Category, Subcategory } from '../../../types/ResponseTypes';

type BottomCategoriesProps = {
    categories: Array<Category>
}

function BottomCategories({ categories }: BottomCategoriesProps): ReactElement {
    function getSubcategoryBlock(subcategory: Subcategory): ReactElement {
        return (
            <li key={subcategory.id}>
                <Link to={subcategory.url || ''}>{subcategory.display_name}</Link>
            </li>
        )
    }

    function getCategoryBlock(category: Category): ReactElement {
        return (
            <div className="links-box" key={category.id}>
                <div className="heading">{category.name}</div>
                <ul>
                    {category.subcategories.map((subcategory) => getSubcategoryBlock(subcategory))}
                </ul>
            </div>
        )
    }

    return (
        <div className="container footer-links">
            <div className="row g-0">
                {categories.map((category) => {
                    return category.subcategories.length > 0 ?
                        getCategoryBlock(category) :
                        undefined
                })}
            </div>
        </div>
    )
}

export default BottomCategories;
