import { ReactElement } from 'react';
import { useFormContext } from 'react-hook-form';

function AccountSettings({ user }: any): ReactElement {
    const { register } = useFormContext();

    return (
        <fieldset>
            <legend>Настройки аккаунта</legend>
            <p>
                <label>Логин</label>
                <input type='text' {...register('username')} id='frm-registrationForm-login'></input>
            </p>
            <p>
                <label>Пароль</label>
                <input type='password' {...register('password')} id='frm-registrationForm-password'></input>
            </p>
            <p>
                <label>Подтвердить пароль</label>
                <input type='password' {...register('password_verify')} id='frm-registrationForm-password_verify'></input>
            </p>
        </fieldset>
    );
}

export default AccountSettings;
