import { countProductsOnPage } from '../constants/Constants';
import { FilterData, SortData } from '../types/ResponseTypes';
import config from './config';

export function getURI(urn: string): string {
    return `${config.HCLBackend.baseUrl}${urn}`;
}

export function getFilterParams(filters: Array<FilterData> = []): string {
    if (!filters || filters.length === 0) {
        return '';
    }
    function getPathToField(pathToField: Array<string>): string {
        return pathToField.map((element) => `[${element}]`).join('');
    }
    return filters.map((filter) => `filters${getPathToField(filter.pathToField)}[${filter.operator}]=${filter.value}`).join('&');
}

export function getSortParams(sorts: Array<SortData> = []): string {
    if (!sorts || sorts.length === 0) {
        return '';
    }

    return sorts.map((sort) => `sort[${sort.index}]=${sort.field}:${sort.ordering}`).join('&');
}

export function getPaginationParams(page: number = 0): string {
    return `pagination[page]=${page}&pagination[pageSize]=${countProductsOnPage}`;
}

export function getUrlToImage(url?: string) {
    return `${config.HCLBackend.baseUrl}${url}`;
}
