import { ReactElement, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useStore } from '../../../store/store';
import { Order } from '../../../types/ResponseTypes';
import { cancelOrder, initPayment } from '../../../api/LayoutApi';
import { observer } from 'mobx-react-lite';

type OrderElementProps = {
    order: Order
}

const OrderElement = observer(({ order }: OrderElementProps) => {
    const { userStore } = useStore();
    const history = useNavigate();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isLoadingPay, setIsLoadingPay] = useState<boolean>(false);

    function getOrderDetailLink() {
        return `/my-account/order-detail/${order.id}`;
    }

    async function cancel() {
        try {
            if(!isLoading) {
                setIsLoading(true);
                await cancelOrder(order.id, userStore.getJWT());
                history(0);
            }
        } finally {
            setIsLoading(false);
        }
    }

    function getDate(date: Date) {
        return new Date(date)
            .toLocaleDateString('ru-RU', { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' });
    }

    async function pay() {
        if (!isLoadingPay) {
            setIsLoadingPay(true);
            const resp = await initPayment(order.id, userStore.getJWT());
            setIsLoadingPay(false);
            if (resp.data.formUrl) {
                window.location.replace(resp.data.formUrl);
            } else {
                // Show error
            }
        }
    }

    
    return (
        <tr>
            <td className='left'>{order.id}</td>
            <td className='left'>{getDate(order.createdAt)}</td>
            <td className='center'>Сумма заказа: {order.total_price_with_tax.toFixed(2)} P</td>
            <td className='center'>Итого к оплате (за вычетом бонусов): {(order.total_price_with_tax -
                (order.bonus_to_spend == null ? 0 : order.bonus_to_spend ))
                .toFixed(2)} P</td>
            <td className='center'>{order.order_status.name}</td>
            <td className='center'>
                <Link className='btn btn-primary margin-right-4' to={getOrderDetailLink()}>Детали заказа</Link>
                {
                    order.order_status && (order.order_status.id === 1 || order.order_status.id === 2) &&
                    <Link className='btn btn-primary' to='' onClick={cancel}>Отмена заказа</Link>
                }
            </td>
            <td className='center container-a-not-href'>
                {
                    order.order_status && order.order_status.id === 2 &&
                    <a className='btn btn-primary custom-a-not-href' onClick={() => {pay()}} style={{pointerEvents: isLoadingPay ? 'none' : 'auto'}}>Оплатить</a>
                }
            </td>
        </tr>
    )

});

export default OrderElement;
