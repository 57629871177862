import { ReactElement } from 'react';
import { useFormContext } from 'react-hook-form';

function Agreements({ user }: any): ReactElement {
    const { register } = useFormContext();

    return (
        <fieldset>
            {
                !user && 
                <>
                    <p className='checkbox'>
                        <label>
                            <input type='checkbox' {...register('agreement_accepted')} id='frm-registrationForm-agreement' />
                            Я согласен с условиями магазина 
                        </label>
                        <span className='staticflag'>
                            <a href='/s10-o-sajjte'>
                                (условия магазина)
                            </a>
                        </span>
                    </p>
                    <br />
                </>
            }
            <p className='checkbox'>
                <label>
                    <input type='checkbox' defaultValue={user?.newsletter_subscribed || true} {...register('newsletter_subscribed')} id='frm-registrationForm-newsletter' />
                    Я согласен с подпиской на новости
                </label>
            </p>
        </fieldset>
    )
}

export default Agreements;