import { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import Logo from '../../../assets/logo.png';
import CartBox from '../CartBox/CartBox';
import SearchBox from '../SearchBox/SearchBox';

type MainRowProps = {
    openMenu: Function
}

function MainRow({ openMenu }: MainRowProps): ReactElement {

    return (
        <div className="row main-row">
            <div className="col-sm-4 box-brand">
                <a onClick={() => openMenu()} id="hamburger">&#x2630;</a>
                <Link to='/' className="brand">
                    <img src={Logo} />
                    <div className="brand-text">Интернет-магазин</div>
                </Link>
            </div>
            <div className="col-sm-4 box-search">
                <SearchBox />
			</div>
            <div className='col-sm-4 box-cart'>
                <CartBox />
            </div>
        </div>
    )
}

export default MainRow;
