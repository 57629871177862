import { observer } from "mobx-react-lite";
import { useForm } from "react-hook-form";
import { Range, getTrackBackground } from 'react-range';
import { useStore } from "../../../store/store";
import { FilterData } from "../../../types/ResponseTypes";

type FilterProps = {
    reloadProducts: Function
}
type Inputs = {
    order: string,
    isNovelties: boolean,
    isSales: boolean,
    isTop: boolean
};

const Filter = observer(({ reloadProducts }: FilterProps) =>  {
    const { register, handleSubmit } = useForm<Inputs>();
    const {filterStore} = useStore();

    function changeSort(order: string) {
        let field = '';
        let ordering;

        if (order.includes('name')) {
            field = 'common_name'
        } else {
            field = 'price'
        }

        if (order.includes('asc')) {
            ordering = 'asc'
        } else {
            ordering = 'desc'
        }

        filterStore.changeSort({
            index: 0,
            field: field,
            ordering: ordering
        });
    }

    const onSubmit = handleSubmit(async (data) => {
        const filters: Array<FilterData> = [];
        if (data.isNovelties) {
            filters.push({
                pathToField: ['novelty'],
                operator: '$eq',
                value: data.isNovelties
            });
        }
        if (data.isSales) {
            filters.push({
                pathToField: ['discount'],
                operator: '$eq',
                value: data.isSales
            });
        }
        if (data.isTop) {
            filters.push({
                pathToField: ['week_top_sale'],
                operator: '$eq',
                value: data.isTop
            });
        }
        filters.push({
            pathToField: ['price'],
            operator: '$gte',
            value: filterStore.priceValues[0]
        });
        filters.push({
            pathToField: ['price'],
            operator: '$lte',
            value: filterStore.priceValues[1]
        });

        filterStore.changeFilters(filters);

        changeSort(data.order);
        reloadProducts();
    });

    return (
        <div id='filter-cnt' className='container filters hide'>
            <div className='row'>
                <div className='col-sm-12'>
                    <form onSubmit={onSubmit} method='post' id='userBarForm'>
                        <div className='filter-box'>
                            <label>Сортировать по:</label>
                            <select {...register('order')} id='frm-userBarForm-order'>
                                <option value='name|asc' selected={true}>А-Я</option>
                                <option value='name|desc'>Я-А</option>
                                <option value='price|asc'>стоимость по возрастанию</option>
                                <option value='price|desc'>стоимость по убыванию</option>
                            </select>
                        </div>
                        <div className='filter-box'>
                            <label>ТОВАРЫ</label>
                            <div className='checkboxes'>
                                <label className='checkbox-container'>
                                    Hовинки
                                    <input type='checkbox' {...register('isNovelties')} id='frm-userBarForm-news' />
                                    <span className='checkmark'></span>
                                </label>
                                <label className='checkbox-container'>
                                    Скидки
                                    <input type='checkbox' {...register('isSales')} id='frm-userBarForm-sale' />
                                    <span className='checkmark'></span>
                                </label>
                                <label className='checkbox-container'>
                                    Популярное
                                    <input type='checkbox' {...register('isTop')} id='frm-userBarForm-tipc' />
                                    <span className='checkmark'></span>
                                </label>
                            </div>
                        </div>
                        <div className='filter-box'>
                            <div className='slider-box'>
                                <label>
                                    <span className='range-amount'>{filterStore.priceValues[0]} Р - {filterStore.priceValues[1]} Р</span>
                                </label>
                                <Range
                                    step={1}
                                    min={filterStore.min}
                                    max={filterStore.max}
                                    values={filterStore.priceValues}
                                    onChange={(values) => filterStore.priceValues = values}
                                    renderTrack={({ props, children }: any) => (
                                        <div
                                            onMouseDown={props.onMouseDown}
                                            onTouchStart={props.onTouchStart}
                                            style={{
                                                ...props.style,
                                                height: "36px",
                                                display: "flex",
                                                width: "100%"
                                            }}
                                            >
                                            <div
                                                ref={props.ref}
                                                style={{
                                                height: "36px",
                                                width: "100%",
                                                borderRadius: "4px",
                                                background: getTrackBackground({
                                                    values: filterStore.priceValues,
                                                    colors: ["#ffffff", "#cf072c", '#ffffff'],
                                                    min: filterStore.min,
                                                    max: filterStore.max
                                                }),
                                                alignSelf: "center"
                                                }}
                                            >
                                                {children}
                                            </div>
                                            </div>
                                        )}
                                    renderThumb={({ props }: any) => (
                                        <div
                                            {...props}
                                            style={{
                                                ...props.style,
                                                border: '1px solid #c5c5c5',
                                                height: '45px',
                                                width: '19px',
                                                backgroundColor: '#f6f6f6'
                                            }}
                                        />
                                    )}
                                />
                            </div>
                        </div>
                        <div className='filter-box filter-box-submit'>
                            <input type='submit' name='setup' className='btn btn-secondary' value='ПРИМЕНИТЬ' />
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
});

export default Filter;
