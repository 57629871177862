import { ReactElement } from 'react';
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.min.css';

type ProductCustomisationProps = {
    setName: Function,
    setNumber: Function
}

function ProductCustomisation({ setName, setNumber }: ProductCustomisationProps): ReactElement {
    return (
      <>
          <div className='form-boxx'>
              <label>имя</label>
              <input type='text' maxLength={11} className='name' onChange={(e) => setName(e.target.value)} />
          </div>
          <div className='form-boxx'>
              <label>номер</label>
              <input type='text' maxLength={3} className='number' onChange={(e) => setNumber(e.target.value)} />
          </div>
      </>
    );
}

export default ProductCustomisation;