import { ReactElement, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import config from '../../api/config';
import { CDEK_PACKAGE_PARAMETERS } from '../../constants/Constants';

function CdekWidget(): ReactElement {
  const { setValue } = useFormContext();
  const [sdekWidget, setSdekWidget] = useState();

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://cdn.jsdelivr.net/npm/@cdek-it/widget@3';
    script.async = true;
    document.body.appendChild(script);

    script.onload = function () {
      let widget;

      if (!widget) {
        //@ts-ignore
        widget = new window.CDEKWidget({
          from: {
            country_code: 'RU',
            city: 'Ярославль',
            postal_code: 150023,
            address: 'ул. Гагарина, 15',
            code: 146,
            city_code: 146,
          },
          root: 'cdekWidget',
          apiKey: config.CDEK.yandexApiKey, // api key яндекса
          canChoose: true,
          servicePath: config.CDEK.servicePath,
          hideFilters: {
            have_cashless: false,
            have_cash: false,
            is_dressing_room: false,
            type: false,
          },
          hideDeliveryOptions: {
            office: false,
            door: false,
          },
          // debug: true, // enable if needed
          goods: [
            {
              length: CDEK_PACKAGE_PARAMETERS.length,
              width: CDEK_PACKAGE_PARAMETERS.width,
              height: CDEK_PACKAGE_PARAMETERS.height,
              weight: CDEK_PACKAGE_PARAMETERS.weight,
            },
          ],
          defaultLocation: 'г. Ярославль',
          lang: 'rus',
          currency: 'RUB',
          tariffs: {
            office: [136],
            door: [137],
          },
          onReady() {},
          onCalculate(calculatedVarations: any, deliveryTo: any) {
            console.log('Расчет стоимости доставки произведен');
          },
          onChoose(type: any, tariff: any, data: any) {
            console.log('Выбор ПВЗ произведен');
            choosePVZv3(type, tariff, data);
          },
        });
      }

      function choosePVZv3(type: any, tariff: any, data: any) {
        if (type == 'office') {
          setValue('delivery_point', data.city_code);
          setValue('delivery_country', 1);
          setValue(
            'desired_delivery_point',
            `ПВЗ: ${data.city}, ${data.address}`
          );
          setValue('delivery_street', data.address);
          setValue('delivery_city', data.city);
          setValue('delivery_filled', !!data.city);
          setValue('delivery_postcode', data.postal_code);
          setValue('tariff', tariff.tariff_code);
        } else if (type == 'door') {
          if (data.city_code) setValue('delivery_point', data.city_code);
          setValue('delivery_country', 1);
          setValue('desired_delivery_point', `Курьер: ${data.formatted}`);
          setValue('delivery_street', data.name);
          setValue('delivery_city', data.city);
          setValue('delivery_filled', !!data.city);
          setValue('delivery_postcode', data.postal_code);
          setValue('delivery_address', data.formatted);
          setValue('tariff', tariff.tariff_code);
        }
      }

      // legacy
      function choosePVZ(wat: any) {
        setValue('delivery_point', wat.PVZ.CityCode);
        setValue('delivery_country', 1);
        setValue(
          'desired_delivery_point',
          `ПВЗ: ${wat.cityName}, ${wat.PVZ.Address}`
        );
        setValue('delivery_street', wat.PVZ.Address);
        setValue('delivery_city', wat.cityName);
        setValue('delivery_postcode', 0);
        setValue('tariff', wat.tarif);
      }

      // legacy
      function chooseAddress(wat: any) {
        console.log('Address use');
        setValue('delivery_point', wat.city);
        setValue('delivery_country', 1);
        setValue('desired_delivery_point', `Курьер: ${wat.address}`);
        setValue('delivery_street', wat.address);
        setValue('delivery_city', wat.cityName);
        setValue('delivery_postcode', 0);
        setValue('tariff', wat.tarif);
      }

      setSdekWidget(widget);
    };
  }, []);

  return <div id='cdekWidget' style={{ height: '500px' }}></div>;
}

export default CdekWidget;
