import React, { ReactElement, useEffect, useState } from 'react';
import axios from 'axios';
import {
    BrowserRouter as Router,
    Routes,
    Route
} from 'react-router-dom';
import Home from './components/Home/Home';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import ProductPage from './components/ProductPage/ProductPage';
import RegistrationPage from './components/RegistrationPage/RegistrationPage';
import LoginPage from './components/LoginPage/LoginPage';
import LostPasswordPage from './components/LostPasswordPage/LostPasswordPage';
import RecoveryPasswordPage from './components/RecoveryPasswordPage/RecoveryPasswordPage';
import { Category, StaticPages, Subcategory } from './types/ResponseTypes';
import { getCategories } from './api/LayoutApi';
import { mapCategories, mapBaseAndBaseCollectionResponse } from './mapping/ResponseMapping';
import { getStaticPages, getExternalStaticPages } from './api/LayoutApi';
import './styles.scss';
import './customStyles.scss';
import CategoryPage from './components/CategoryPage/CategoryPage';
import UserProfilePage from './components/UserProfilePage/UserProfilePage';
import { store, StoreContext, useStore } from './store/store';
import AccountPage from './components/AccountPage/AccountPage';
import CartPage from './components/CartPage/CartPage';
import DeliveryPaymentPage from './components/DeliveryPaymentPage/DeliveryPaymentPage';
import OrdersPage from './components/OrdersPage/OrdersPage';
import OrderDetailPage from './components/OrderDetailPage/OrderDetailPage';
import InvoiceDataPage from './components/InvoiceDataPage/InvoiceDataPage';
import StaticPage from './components/StaticPage/StaticPage';
import VerificationPage from './components/VerificationPage/VerificationPage';
import OrderCompletePage from './components/OrderCompletePage/OrderCompletePage';
import { observer } from 'mobx-react-lite';
import SuccessesPaymentPage from './components/SuccessesPaymentPage/SuccessesPaymentPage';
import FailPaymentPage from './components/FailPaymentPage/FailPaymentPage';

const App = observer (() => {
    const [categories, setCategories] = useState<Array<Category>>([]);
    const [menu, setMenu] = useState<Array<StaticPages>>([]);
    const { productStore, categoriesStore, userStore, cartStore, bonusStore, jerseyConstructorStore } = useStore();

    axios.interceptors.response.use(
        function (config) {
            return config;
        },
        async function (error) {
            if(error?.response?.status === 401) {
                return userStore.logout();
            }
    
            return Promise.reject(error);
        }
    );
    
    function compare(a: Subcategory, b: Subcategory) {
        return a.display_order - b.display_order;
    }

    function mergeInternalAndExternalPages(staticPages: Array<StaticPages>, externalStaticPages: Array<StaticPages>) {
        const tmp = [ ...staticPages, ...externalStaticPages ];
        return tmp.sort((a, b) => a.attributes.display_order - b.attributes.display_order);
    }

    useEffect(() => {
        const getData = async () => {
            const [categoryList, staticPages, externalStaticPages] = await Promise.all([
                getCategories(),
                getStaticPages(),
                getExternalStaticPages(),
                productStore.loadNovelties(),
                productStore.loadTopWeek()
            ]);
            const topMenuLinksList = mergeInternalAndExternalPages(mapBaseAndBaseCollectionResponse(staticPages), mapBaseAndBaseCollectionResponse(externalStaticPages));
            const unsortedCategories = mapCategories(mapBaseAndBaseCollectionResponse(categoryList));
            const sortedCategories = unsortedCategories.sort(compare);
            sortedCategories.forEach((category) => {
                category.subcategories = category.subcategories.sort(compare)
            });
            
            setMenu(topMenuLinksList);
            setCategories(sortedCategories);
            categoriesStore.categories = sortedCategories;
        }
        getData();
    }, []);

    useEffect(() => {
        userStore.loadUserInfo();
        cartStore.loadCartInfo();
        bonusStore.loadBonusInfo();
        jerseyConstructorStore.loadData();
    }, []);

    return (
        <StoreContext.Provider value={store}>
            <Router>
                <div>
                    <Header categories={categories} menu={menu} />
                    <Routes>
                        <Route path='/' element={<Home />} />
                        <Route path='/registration' element={<RegistrationPage />} />
                        <Route path='/my-account/profile' element={<UserProfilePage />} />
                        <Route path='/login' element={<LoginPage />} />
                        <Route path='/login/lost-password' element={<LostPasswordPage />} />
                        <Route path='/my-account' element={<AccountPage />} />
                        <Route path='/my-account/orders' element={<OrdersPage />} />
                        <Route path='/my-account/order-detail/:orderId' element={<OrderDetailPage />} />
                        <Route path='/login/password-recovery' element={<RecoveryPasswordPage />} />
                        <Route path='/cart/content' element={<CartPage />} />
                        <Route path='/cart/delivery-payment' element={ userStore.isLogged ? <DeliveryPaymentPage /> : <Home /> } />
                        <Route path='/cart/invoice-data' element={ userStore.isLogged ? <InvoiceDataPage /> : <Home /> } />
                        <Route path='/cart/recapitulation' element={ userStore.isLogged ? <VerificationPage /> : <Home /> } />
                        <Route path='/cart/order-finished' element={ userStore.isLogged ? <OrderCompletePage /> : <Home /> } />
                        <Route path='/product/:productId' element={<ProductPage />} />
                        {
                            menu && menu.map((element) => <Route key={element.id} path={element.attributes.url} element={<StaticPage menu={menu}/>} />)
                        }
                        <Route path='/card-payment/successfull' element={<SuccessesPaymentPage />} />
                        <Route path='/card-payment/fail' element={<FailPaymentPage />} />
                        <Route path='/*' element={<CategoryPage />} />
                    </Routes>
                    <Footer categories={categories} menu={menu} />
                </div>
            </Router>
        </StoreContext.Provider>
    );
});

export default App;
