import { ReactElement, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useStore } from '../../../store/store';
import CdekWidget from '../../CdekWidget/CdekWidget';

function Cdek(): ReactElement {
  const { register, setValue } = useFormContext();
  const { orderStore } = useStore();

  useEffect(() => {
    const getData = async () => {
      setValue(
        'delivery_postcode',
        orderStore.currentOrder.delivery_postcode ||
          orderStore.currentOrder.payment_postcode ||
          orderStore.userStore.currentUser!.postcode
      );
    };
    getData();
    setValue('delivery_point', null);
    setValue('delivery_postcode', null);
    setValue('delivery_address', null);
  }, []);

  return (
    <fieldset>
      <label htmlFor='delivery'>Информация о доставке</label>
      <div id='deliveryDiv' className='deliveryDiv'>
        <p>
          <label htmlFor='frm-invoiceForm-delivery_name'>Имя</label>
          <input
            id='frm-invoiceForm-delivery_name'
            {...register('delivery_name')}
          />
        </p>
        <p>
          <input
            id='desired_delivery_point'
            {...register('desired_delivery_point')}
            disabled={true}
          />
        </p>
        <CdekWidget />
      </div>
    </fieldset>
  );
}

export default Cdek;
