import { makeAutoObservable } from 'mobx'
import { whoAmI } from '../../api/LayoutApi';
import { CurrentUser } from '../../types/ResponseTypes';

class UserStore {
    constructor() {
        makeAutoObservable(this)

        this.currentUser = null;
    }

    jwt = '';
    currentUser: CurrentUser | null;
    isLogged = !!window.localStorage.getItem('jwt');

    getJWT() {
        return this.jwt ? this.jwt : window.localStorage.getItem('jwt') || undefined;
    }

    async loadUserInfo() {
        const token = this.getJWT();
        if(!token) {
            return null;
        }
        const response = await whoAmI(token);
        this.currentUser = response && { ...response.data, country: response.data?.country?.id, delivery_country: response.data?.delivery_country?.id };

        return this.currentUser;
    }

    saveJwt(jwt: string) {
        this.jwt = jwt
        window.localStorage.setItem('jwt', jwt);
    }

    async login(jwt: string) {
        const userData = await whoAmI(jwt);
        window.localStorage.setItem('jwt', jwt);
        this.jwt = jwt;
        this.currentUser = userData.data;
        this.isLogged = true;
    }

    logout() {
        window.localStorage.removeItem('jwt');
        this.jwt = '';
        this.currentUser = null;
        this.isLogged = false;
    }
}

export default UserStore;
