import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CUSTOMISATION_JERSEY_ELEMENTS } from '../../constants/Constants';
import { mapProductToCartItem } from '../../mapping/ResponseMapping';
import { useStore } from '../../store/store';
import { CartItem, Product } from '../../types/ResponseTypes';
import JerseyLeftBlock from './JerseyLeftBlock/JerseyLeftBlock';
import JerseyPhotoBlock from './JerseyPhotoBlock/JerseyPhotoBlock';
import JerseyRightBlock from './JerseyRightBlock/JerseyRightBlock';

const JerseyConstructor = observer(() => {
  const [name, setName] = useState<string>('');
  const [number, setNumber] = useState<string>('');
  const [isWhiteJersey, setIsWhiteJersey] = useState<boolean>(true);
  const [selectedJersey, setSelectedJersey] = useState<Product>();
  const [isNumberInSleeves, setIsNumberInSleeves] = useState<boolean>(false);
  const [isCaptain, setCaptain] = useState<boolean>(false);
  const [isAltCaptain, setAltCaptain] = useState<boolean>(false);
  const [isMemorialRibbon, setMemorialRibbon] = useState<boolean>(false);
  const { cartStore, jerseyConstructorStore } = useStore();
  const history = useNavigate();

  function calculatePrice(): number {
    let price = 0;

    price += selectedJersey?.price || 0;
    price +=
      jerseyConstructorStore.getCustomisationPriceByName(
        CUSTOMISATION_JERSEY_ELEMENTS.number
      ) * number.length;
    price +=
      jerseyConstructorStore.getCustomisationPriceByName(
        CUSTOMISATION_JERSEY_ELEMENTS.name
      ) * name.length;
    price += isNumberInSleeves
      ? jerseyConstructorStore.getCustomisationPriceByName(
          CUSTOMISATION_JERSEY_ELEMENTS.sleevesNumber
        ) *
        number.length *
        2
      : 0;
    price += isCaptain
      ? jerseyConstructorStore.getCustomisationPriceByName(
          CUSTOMISATION_JERSEY_ELEMENTS.captain
        )
      : 0;
    price += isAltCaptain
      ? jerseyConstructorStore.getCustomisationPriceByName(
          CUSTOMISATION_JERSEY_ELEMENTS.altCaptain
        )
      : 0;
    price += isMemorialRibbon
      ? jerseyConstructorStore.getCustomisationPriceByName(
          CUSTOMISATION_JERSEY_ELEMENTS.memorialRibbon
        )
      : 0;

    return price;
  }

  async function addToCart() {
    if (!selectedJersey) {
      return;
    }

    const customisation = {
      number: number,
      text: name,
      sleeves_number: isNumberInSleeves,
      captain: isCaptain,
      alt_captain: isAltCaptain,
      memorial_ribbon: isMemorialRibbon,
    };
    const data: CartItem = mapProductToCartItem(
      selectedJersey,
      calculatePrice(),
      customisation
    );
    data.amount = 1;

    try {
      await cartStore.addItem(data);
      history('/cart/content');
    } catch (e: any) {
      // TODO: Do something if error
    }
  }

  return (
    <>
      <div className='row heading-row'>
        <div className='col-sm-6 heading'>
          <h2>Конструктор джерси</h2>
        </div>
      </div>
      <div className='row products-row'>
        <div className='col-sm-3'>
          <JerseyLeftBlock
            jerseyOptions={jerseyConstructorStore.jerseyOptions}
            setName={setName}
            setNumber={setNumber}
            setIsWhite={setIsWhiteJersey}
            setSelectedJersey={setSelectedJersey}
            price={calculatePrice()}
            addToCart={addToCart}
          />
        </div>
        <div className='col-sm-6'>
          <JerseyPhotoBlock
            name={name}
            number={number}
            isWhiteJersey={isWhiteJersey}
          />
        </div>
        <div className='col-sm-3'>
          <JerseyRightBlock
            setIsNumberInSleeves={setIsNumberInSleeves}
            isNumberInSleeves={isNumberInSleeves}
            setCaptain={setCaptain}
            isCaptain={isCaptain}
            setAltCaptain={setAltCaptain}
            isAltCaptain={isAltCaptain}
            setMemorialRibbon={setMemorialRibbon}
            isMemorialRibbon={isMemorialRibbon}
          />
        </div>
      </div>
    </>
  );
});

export default JerseyConstructor;
