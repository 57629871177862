import { ReactElement } from 'react';

type JerseyRightBlockProps = {
    setIsNumberInSleeves: Function,
    isNumberInSleeves: boolean,
    setCaptain: Function,
    isCaptain: boolean,
    setAltCaptain: Function,
    isAltCaptain: boolean,
    setMemorialRibbon: Function,
    isMemorialRibbon: boolean
}

function JerseyRightBlock({setIsNumberInSleeves, isNumberInSleeves, setCaptain, isCaptain, setAltCaptain, isAltCaptain, setMemorialRibbon, isMemorialRibbon}: JerseyRightBlockProps): ReactElement {
    function changeCaptain(value: boolean) {
        if (value === true) {
            setAltCaptain(false);
        } 
        setCaptain(value);
    }

    function changeAltCaptain(value: boolean) {
        if (value === true) {
            setCaptain(false);
        } 
        setAltCaptain(value);
    }

    return (
        <div>
            <p className='checkbox'>
                <label>
                    <input className='custom-checkbox' type='checkbox' checked={isNumberInSleeves} onChange={(e) => setIsNumberInSleeves(e.target.checked)} />
                    &nbsp;
                    Номер на рукавах
                </label>
            </p>
            <p className='checkbox'>
                <label>
                    <input className='custom-checkbox' type='checkbox' checked={isCaptain} onChange={(e) => changeCaptain(e.target.checked)} />
                    &nbsp;
                    Отметка капитана
                </label>
            </p>
            <p className='checkbox'>
                <label>
                    <input className='custom-checkbox' type='checkbox' checked={isAltCaptain} onChange={(e) => changeAltCaptain(e.target.checked)} />
                    &nbsp;
                    Отметка ассистента
                </label>
            </p>
            <p className='checkbox'>
                <label>
                    <input className='custom-checkbox' type='checkbox' checked={isMemorialRibbon} onChange={(e) => setMemorialRibbon(e.target.checked)} />
                    &nbsp;
                    Памятная лента
                </label>
            </p>
        </div>
    )
}

export default JerseyRightBlock;
