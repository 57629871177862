import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useStore } from '../../store/store';

const AccountPage = observer(() => {
    const { userStore, bonusStore } = useStore();

    useEffect(() => {
        const getData = async () => {
            await userStore.loadUserInfo(); 
        }
        getData();
    }, []);

    function getDate() {
        return userStore.currentUser && new Date(userStore?.currentUser.createdAt)
            .toLocaleDateString("ru-RU", { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' });
    }

    return (
        <div className="container layout">
            <main className="row">
                <div className="col-sm-12 content content-full content-left">
                    <div className="heading"><h1>Мой аккаунт</h1></div>
                    <div className="account">
                        <h2>Клиент: {userStore?.currentUser?.name} {userStore?.currentUser?.surname}</h2>
                        <p>Зарегистрирован: {getDate()}</p>
                        <p>У вас в наличии: {bonusStore.currentBonusBalance} бонусов</p>
                        <p>Количество заказов: {userStore.currentUser?.orders.length}</p>
                        <Link className="btn btn-primary" to="/my-account/profile">
                            <span>Профиль</span>
                        </Link>&nbsp;&nbsp;
                        <Link className="btn btn-primary" to="/my-account/orders">
                            <span>Заказы</span>
                        </Link>
                    </div>
                </div>
            </main>
        </div>
    );
});

export default AccountPage;
