import { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.min.css';
import InnerImageZoom from 'react-inner-image-zoom';
import { Product } from '../../../types/ResponseTypes';
import { getUrlToImage } from '../../../api/ApiHelper';

type ProductImageProps = {
    product?: Product
    variation?: Product
}

function ProductImage({ product, variation }: ProductImageProps): ReactElement {
    return (
        <div className='product-photo images'>
            <div className='imagebox'>
                <Link to='' className='fancybox'>
                    {
                        product &&
                        <InnerImageZoom 
                            src={getUrlToImage(variation?.image?.main_image?.url)} 
                            zoomPreload={true}
                            zoomType="hover"
                            zoomScale={1}
                            hideCloseButton={true}
                            hideHint={true}
                            imgAttributes={{
                                alt: product?.common_name || product?.name,
                                id: 'gmain'
                            }}
                        />
                    }
                </Link>
            </div>
        </div>
    );
}

export default ProductImage;
