import { ReactElement, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import CommonProductList from '../ProductList/CommonProductList';
import CategoryProductList from '../ProductList/CategoryProductList';
import Filter from './Filter/Filter';
import { searchPagePrefix } from '../../constants/Constants';
import { useStore } from '../../store/store';

function CategoryPage(): ReactElement {
    const { categoriesStore, filterStore, productStore } = useStore();
    const [page, setPage] = useState<number>(0);
    const [header, setHeader] = useState<string | undefined>('');
    let location = useLocation();

    useEffect(() => {
        const getData = async () => {
            if(categoriesStore.categories) {
                const path = location.pathname;
                const isCategoryFilterNeeded = !path.startsWith('/catalog');

                await filterStore.initFilter(location.pathname, isCategoryFilterNeeded);
                setPage(0);
                if (path.startsWith(searchPagePrefix)) {
                    setHeader(undefined);
                } else {
                    const { name } = categoriesStore.getCategoryAndSubcategoryIds(path);
                    setHeader(name);
                }
            }
        }
        getData();
    }, [location, categoriesStore.categories]);

    useEffect(() => {
        reloadProducts(page);
    }, [page, filterStore.filters, filterStore.categoryFilter, filterStore.searchFilter]);

    function reloadProducts(pageNumber: number) {
        productStore.loadProducts(pageNumber);
        productStore.loadCountProductsWithFilters();
    }

    function doFilter() {
        setPage(0);
        reloadProducts(0);
    }

    return (
        <>
            <Filter reloadProducts={doFilter} />
            <div className='container layout'>
                <CategoryProductList header={header} page={page} setPage={setPage} />
            </div>
            <div className='container hp-products'>
                <CommonProductList header='Новинки' products={productStore.novelties} />
            </div>
        </>
    );
}

export default CategoryPage;
