import React, { ReactElement } from 'react';
import { Category, StaticPages } from '../../types/ResponseTypes';
import BottomCategories from './BottomCategories/BottomCategories';
import BottomMenu from './BottomMenu/BottomMenu';

type FooterProps = {
    categories: Array<Category>
    menu: Array<StaticPages>
}

function Footer({ categories, menu }: FooterProps): ReactElement {
    return (
        <footer>
            <BottomCategories categories={categories} />
            <BottomMenu  menu={menu} />
        </footer>
    )
}

export default Footer;
