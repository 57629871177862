import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useStore } from '../../store/store';
import { Subcategory } from '../../types/ResponseTypes';
import Pagination from '../Pagination/Pagination';
import ProductListItem from './ProductListItem/ProductListItem';

type ProductListProps = {
	page: number,
	setPage: Function,
    header?: string
}

const CategoryProductList = observer(({ header, page, setPage }: ProductListProps) =>  {
	const { productStore, categoriesStore } = useStore();
	const [subcategories, setCategories] = useState<Array<Subcategory>>([]);
	const location = useLocation();

	useEffect(() => {
		const path = location.pathname;
		const subcategoryList = categoriesStore.getSubcategoriesByPath(path);
		setCategories(subcategoryList);
	}, []);

    return (
		<div className='row'>
			<aside className='col-sm-2'>
				<div className='submenu'>
					<ul>
						{subcategories && subcategories.map((el) => <li><Link to={el.url || ''}>{el.display_name}</Link></li>)}
					</ul>
				</div>
			</aside>
			<main className='col-sm-10'>
				<div className='products-list-filter'>
					<div className='heading'>{header}</div>
					<div className='products-list snippetDiv' id='snippet--productList'>
						{ productStore.products.map((product) => <ProductListItem key={product.id} product={product} isCommon={false} />) }
					</div>
					<Pagination page={page} setPage={setPage} />
				</div>
			</main>
		</div>
    );
});

export default CategoryProductList;
