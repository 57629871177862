import { ReactElement } from "react";
import { Product } from "../../../types/ResponseTypes";

type ProductBodyProps = {
    product: Product,
    isCommon: boolean
}

function ProductBody({ product, isCommon }: ProductBodyProps): ReactElement {
    return (
        <div className="product-body">
            <div className="product-name">{product.common_name}</div>
            <div className="product-price">
                {product.price} Р
                {product?.discount && product.default_price && <span>{product.default_price}</span> }
            </div>
            {
                !isCommon &&
                <div className="btn btn-primary">ПОДРОБНЕЕ</div>
            }
        </div>
    );
}

export default ProductBody;
