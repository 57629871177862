export default (function () {
    return {
        HCLBackend: {
            baseUrl: `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_HOST}`
        },
        CDEK: {
            yandexApiKey: process.env.REACT_APP_YANDEX_KEY,
            servicePath: `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_HOST}/service.php`
        }
    }
})()
