import { ReactElement, useEffect, useRef, useState } from 'react';
import { Product } from '../../../types/ResponseTypes';
import PathToProduct from '../PathToProduct/PathToProduct';
import ProductImageGallery from '../ProductImageGallery/ProductImageGallery';
import { getProductsInGroup } from '../../../api/LayoutApi';
import ProductCartBox from '../ProductCartBox/ProductCartBox';

type ProductDetailProps = {
  product?: Product;
};

function ProductDetail({ product }: ProductDetailProps): ReactElement {
  const [isMinState, setIsMinState] = useState(true);
  const tableRef = useRef<HTMLDivElement>(null);
  const gradientRef = useRef<HTMLDivElement>(null);
  const btnRef = useRef<HTMLDivElement>(null);
  const [productsInGroup, setProductsInGroup] = useState<Array<Product>>([]);
  const [variation, setVariation] = useState<Product>();
  const [errorMessages, setErrorMessages] = useState<Array<string>>([]);

  useEffect(() => {
    const element = tableRef.current;
    const gradient = gradientRef.current;
    const btn = btnRef.current;
    if (isMinState) {
      if (element!.scrollHeight < 300) {
        gradient!.style.display = 'none';
        btn!.style.display = 'none';
      } else {
        gradient!.style.display = 'flex';
        btn!.style.display = 'flex';
        element!.style.height = '300px';
      }
    }
  });

  useEffect(() => {
    const getData = async () => {
      if (product && product.group_by_id_1c) {
        const productList = (await getProductsInGroup(product.group_by_id_1c))
          .data;
        setProductsInGroup(productList);

        const activeVariation = productList.find(
          (v: Product) => v.id == product.id
        );
        setVariation(activeVariation);
      } else {
        setVariation(product);
      }
    };
    getData();
  }, [product]);

  function onButtonClick(event: any) {
    const element = tableRef.current;
    const gradient = gradientRef.current;

    if (element !== null) {
      if (isMinState) {
        element.style.height = element.scrollHeight + 45 + 40 + 'px';
        gradient!.style.display = 'none';
      } else {
        element.style.height = '300px';
        gradient!.style.display = 'block';
      }
      setIsMinState((value) => !value);
    }
  }

  function addError(message: string) {
    if (errorMessages.some((error) => error === message)) {
      return;
    }

    const tmp = JSON.parse(JSON.stringify(errorMessages));
    tmp.push(message);
    setErrorMessages(tmp);
  }

  const onVariationChange = (p: Product) => {
    setVariation(p);
  };

  return (
    <div id='collapsed-detail' className='product-body productdetail'>
      <PathToProduct product={product} />
      <h1>{product?.common_name || product?.name}</h1>
      <div
        ref={tableRef}
        className='product-desk'
        dangerouslySetInnerHTML={{ __html: product?.detail_info || '' }}
      />
      <div
        ref={gradientRef}
        id='collapsed-gradient'
        className='white-gradient-bottom'
      ></div>
      <div ref={btnRef} className='collapsed-control-wrapper'>
        <a onClick={onButtonClick} className='collapsed-control button'>
          {isMinState ? 'Больше ⇓' : 'Меньше ⇑'}
        </a>
      </div>
      <ProductImageGallery product={product} variation={variation} />
      <ProductCartBox
        setErrorMessage={addError}
        product={product}
        productsInGroup={productsInGroup}
        onVariationChange={onVariationChange}
      />
    </div>
  );
}

export default ProductDetail;
