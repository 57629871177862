import { ReactElement } from "react";

function FailPaymentPage(): ReactElement {
    return (
        <div className="container layout">
            <main className="row">
                <div className="col-sm-12 content content-full content-left">
                    <div className="heading"><h1>Платежное поручение</h1></div>
                    <div className="account">
                        <p>Оплата не прошла. Обратитесь к администратору. Спасибо!</p>
                    </div>
                </div>
            </main>
        </div>
    );
}

export default FailPaymentPage;
