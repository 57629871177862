export const taxId = 1;

export const tax = {
    id: taxId,
    name: 'НДС',
    percent_value: 20
}

export function thereAreOnly(avaliableCount: number) {
    return `На складе сейчас доступно ${avaliableCount} шт.`;
}

export const searchPagePrefix = "/q-";

export const countProductsOnPage = 15;

export const DLS_MAX_BONUS_WITHDRAW_PERCENT = 30;

export enum CUSTOMISATION_JERSEY_ELEMENTS {
    number = 'number',
    name = 'text',
    sleevesNumber = 'sleeves_number',
    captain = 'captain',
    altCaptain = 'alt_captain',
    memorialRibbon = 'memorial_ribbon'
}

export enum CDEK_PACKAGE_PARAMETERS {
    length = 25,
    width = 25,
    height = 25,
    weight = 2
}
