import { ReactElement, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Country } from '../../../types/ResponseTypes';

type RussianPostProps = {
    countries: Array<any>,
    delivery: boolean,
    setDelivery: Function
}

function RussianPost({countries, delivery, setDelivery}: RussianPostProps): ReactElement {
    const { register, setValue } = useFormContext();
    const [countrySelectedId, setCountrySelectedId] = useState<Number>(1);
    useEffect(() => {
        const scriptWidgetImport = document.createElement('script');
        scriptWidgetImport.src = 'https://widget.pochta.ru/map/widget/widget.js';
        scriptWidgetImport.async = true;
        document.body.appendChild(scriptWidgetImport);

        function deliveryHandler(e: any) {
            setValue('tariff', e.mailType);
            setValue('weight', e.weight);
            setValue('delivery_city', `${e.regionTo}, ${e.areaTo ? `${e.areaTo}, ` : '' }${e.cityTo}`);
            setValue('delivery_postcode', e.indexTo);
        }

        scriptWidgetImport.onload = () => {
            //@ts-ignore
            ecomStartWidget({
                // id: 39671 stg, 9609 prod
                id: Number(process.env.REACT_APP_RU_POST_WIDGET_ID),
                callbackFunction: deliveryHandler,
                containerId: 'ecom-widget'
            });
        }

    }, []);

    function setSelectedOption(e:any) {
        setCountrySelectedId(e.target.value);
    }

    return (
        <fieldset>
            <p className='checkbox'>
                <label htmlFor='delivery'>
                    <input type='checkbox' id='delivery' checked={delivery} onClick={() => {setDelivery(!delivery)}} />
                    Информация о доставке (заполните разницу с данными счета)
                </label>
            </p>
            {
                delivery &&
                <div id='deliveryDiv' className='deliveryDiv'>
                    <p>
                        <label htmlFor='frm-invoiceForm-delivery_name'>Имя</label> 
                        <input id='frm-invoiceForm-delivery_name' {...register('delivery_name', {required: 'Пожалуйста, введите имя'})} />
                    </p>
                    <p>
                        <label htmlFor='frm-invoiceForm-delivery_street'>Улица</label> 
                        <input id='frm-invoiceForm-delivery_street' {...register('delivery_street', {required: 'Пожалуйста, введите улицу'})} />
                    </p>
                    <p>
                        <label htmlFor='frm-invoiceForm-delivery_street_number'>Номер дома</label> 
                        <input id='frm-invoiceForm-delivery_street_number' {...register('delivery_building_number', {required: 'Пожалуйста, введите номер дома'})} />
                    </p>
                    <p>
                        <label htmlFor='frm-invoiceForm-delivery_room_number'>Номер квартиры</label> 
                        <input {...register('delivery_flat_number')} id='frm-invoiceForm-delivery.roomNumber' />
                    </p>
                    <p>
                        <label htmlFor='frm-invoiceForm-delivery_city'>Город</label> 
                        <input id='frm-invoiceForm-delivery_city' {...register('delivery_city', {required: 'Пожалуйста, введите город'})} />
                    </p>
                    <p>
                        <label htmlFor='frm-invoiceForm-delivery_zip'>Почтовый индекс</label> 
                        <input id='frm-invoiceForm-delivery_zip' {...register('delivery_postcode', {required: 'Пожалуйста, введите Почтовый индекс'})} />
                    </p>
                    <p>
                        <label htmlFor='frm-invoiceForm-id_delivery_country'>Страна доставки</label> 
                        <select {...register('delivery_country')} id='frm-invoiceForm-id_delivery_country' onChange={setSelectedOption}>
                            {countries.map((country: Country) => (
                                <option key={country.id} value={country.id} selected={ country.id == countrySelectedId }>
                                    {country.attributes.name}
                                </option>
                            ))}
                        </select>
                    </p>
                </div>
            }

            <div id='ecom-widget' hidden={!(delivery && countrySelectedId == 1)} style={{height: '500px'}}/>
        </fieldset>
    );
}

export default RussianPost;
