import { ReactElement, useState } from 'react';
import { useFormContext } from 'react-hook-form';

type Country = {
    id: number,
    attributes: {
        name: string
    }
}

type UserDeliveryInfo = {
    countries: Array<Country>,
    user?: any
}

function DeliveryInfo({ countries, user }: UserDeliveryInfo): ReactElement {
    const [isNeedDelivery, setIsNeedDelivery] = useState<boolean>(false);
    const { register } = useFormContext();

    return (
        <fieldset>
            <legend>Информация о доставке</legend>
            <p className='checkbox'>
                <label>
                    <input type='checkbox' {...register('delivery')} id='delivery' onChange={(e) => setIsNeedDelivery(e.target.checked)} />
                    Информация о доставке (заполните разницу с данными счета)
                </label>
            </p>
            {
                isNeedDelivery &&
                <div id='deliveryDiv'>
                    <p>
                        <label>Имя</label>
                        <input type='text' {...register('delivery_name')} id='frm-registrationForm-delivery_name' />
                    </p>
                    <p>
                        <label>Улица</label>
                        <input type='text' {...register('delivery_street')} id='frm-registrationForm-delivery_street' />
                    </p>
                    <p>
                        <label>Номер дома</label>
                        <input type='text' {...register('delivery_building_number')} id='frm-registrationForm-delivery_street_number' />
                    </p>
                    <p>
                        <label>Город</label>
                        <input type='text' {...register('delivery_city')} id='frm-registrationForm-delivery_city' />
                    </p>
                    <p>
                        <label>Почтовый индекс</label>
                        <input type='text' {...register('delivery_postcode')} id='frm-registrationForm-delivery_zip' />
                    </p>
                    <p>
                        <label>Страна доставки</label>
                        <select {...register('delivery_country')} id='frm-registrationForm-id_delivery_country'>
                            {countries.map(country => (
                                <option key={country.id} value={country.id} selected={user && user?.delivery_country && country.id === user?.delivery_country}>
                                    {country.attributes.name}
                                </option>
                            ))}
                        </select>
                    </p>
                </div>
            }
        </fieldset>
    );
}

export default DeliveryInfo;
