import { ReactElement, useRef } from 'react';

type FlashAlertProps = {
    message: string | ReactElement| undefined,
    close: Function,
    isGreen?: boolean
}

function FlashAlert({ message, close, isGreen=false } : FlashAlertProps): ReactElement {
    const errorRef = useRef<HTMLDivElement>(null);
    const className = isGreen ? 'flashalert flashalert-success' : 'flashalert flashalert-error';

    return (
        <div ref={errorRef} className={className}>
            <button className='flashclose' onClick={() => close()}>
                <strong>x</strong>
            </button>
            <div dangerouslySetInnerHTML={{ __html: message?.toString() || '' }} />
        </div>
    );
}

export default FlashAlert;
