import r0 from './0 r.svg';
import r1 from './1 r.svg';
import r2 from './2 r.svg';
import r3 from './3 r.svg';
import r4 from './4 r.svg';
import r5 from './5 r.svg';
import r6 from './6 r.svg';
import r7 from './7 r.svg';
import r8 from './8 r.svg';
import r9 from './9 r.svg';
import w0 from './0 w.svg';
import w1 from './1 w.svg';
import w2 from './2 w.svg';
import w3 from './3 w.svg';
import w4 from './4 w.svg';
import w5 from './5 w.svg';
import w6 from './6 w.svg';
import w7 from './7 w.svg';
import w8 from './8 w.svg';
import w9 from './9 w.svg';

export const redNumbers = [
    r0, r1, r2, r3, r4, r5, r6, r7, r8, r9
];

export const whiteNumbers = [
    w0, w1, w2, w3, w4, w5, w6, w7, w8, w9
];
