import React, { ReactElement, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { StaticPages, Footer } from '../../../types/ResponseTypes';
import { getFooter } from '../../../api/LayoutApi';
import { mapBaseAndBaseCollectionResponse } from '../../../mapping/ResponseMapping';

type BottomMenuProps = {
  menu: Array<StaticPages>;
};

function BottomMenu({ menu }: BottomMenuProps): ReactElement {
  const [footer, setFooter] = useState<Footer>();
  useEffect(() => {
    const getData = async () => {
      const footerResponse = await getFooter();
      setFooter(mapBaseAndBaseCollectionResponse(footerResponse));
    };
    getData();
  }, []);

  function getReactLinkComponent(element: StaticPages): ReactElement<any, any> {
    return element.attributes.url?.startsWith('http') ? (
      <li key={element?.attributes.url}>
        <a href={element.attributes.url} target='_blank'>
          {element.attributes.name}
        </a>
      </li>
    ) : (
      <li key={element.id}>
        <Link to={element.attributes.url || '/'}>
          {element.attributes.name}
        </Link>
      </li>
    );
  }

  return (
    <div className='container bottom-links'>
      <div className='row'>
        <div className='col-sm-12'>
          <ul>{menu?.map((el) => getReactLinkComponent(el))}</ul>
          <div className='copyright'>
            <p>
              {footer?.attributes.text}
              &nbsp;
              <a href={footer?.attributes.url} target='_blank'>
                {footer?.attributes.link_text}
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BottomMenu;
