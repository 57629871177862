import { ReactElement, useEffect, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { mapBaseAndBaseCollectionResponse } from '../../mapping/ResponseMapping';
import { getCountries, updateProfile } from '../../api/LayoutApi';
import UserInfo from '../RegistrationPage/UserInfo/UserInfo';
import AccountSettings from '../RegistrationPage/AccountSettings/AccountSettings';
import DeliveryInfo from '../RegistrationPage/DeliveryInfo/DeliveryInfo';
import FlashAlert from '../FlashAlert/FlashAlert';
import Agreements from '../RegistrationPage/Agreements/Agreements';
import { useStore } from '../../store/store';

type Inputs = {
    id: number,
    name: string,
    surname: string,
    patronymic: string,
    email: string,
    phone: string,
    birth: Date,
    street: string,
    building_number: string,
    city: string,
    postcode: string,
    delivery_name: string,
    delivery_street: string,
    delivery_building_number: string,
    delivery_city: string,
    delivery_postcode: string,
    delivery_country: number,
    username: string,
    password: string,
    password_verify: string,
    newsletter_subscribed: boolean,
    agreement_accepted: boolean,
    country: number,
    isAlreadyExists: boolean
};
  
const schema = yup.object().shape({
    name: yup.string().required('Пожалуйста, введите Имя'),
    surname: yup.string().required('Пожалуйста, введите Фамилию'),
    email: yup.string().required('Пожалуйста, введите Ваш email'),
    phone: yup.string().required('Пожалуйста, введите номер телефона'),
    username: yup.string().required('Пожалуйста, введите логин'),
    password_verify: yup.string().oneOf([yup.ref('password'), null], 'Пароли должны совпадать')
});

function UserProfilePage(): ReactElement {    
    const [countries, setCountries] = useState([]);
    const [user, setUser] = useState<any>();
    const { userStore } = useStore();
    const methods = useForm<Inputs>({
        resolver: yupResolver(schema)
    });

    useEffect(() => {
        const getData = async () => {
            setUser(await userStore.loadUserInfo());
            const response = await getCountries();
            setCountries(mapBaseAndBaseCollectionResponse(response));
        }
        getData();
    }, []);

    useEffect(() => {
        if (user) {
            methods.setValue('name', user.name);
            methods.setValue('surname', user.surname);
            methods.setValue('patronymic', user.patronymic);
            methods.setValue('email', user.email);
            methods.setValue('phone', user.phone);
            methods.setValue('birth', user.birth);
            methods.setValue('street', user.street);
            methods.setValue('building_number', user.building_number);
            methods.setValue('city', user.city);
            methods.setValue('postcode', user.postcode);
            methods.setValue('delivery_name', user.delivery_name);
            methods.setValue('delivery_street', user.delivery_street);
            methods.setValue('delivery_building_number', user.delivery_building_number);
            methods.setValue('delivery_city', user.delivery_city);
            methods.setValue('delivery_postcode', user.delivery_postcode);
            methods.setValue('delivery_country', user.delivery_country);       
            methods.setValue('username', user.username);
            methods.setValue('newsletter_subscribed', user.newsletter_subscribed);
            methods.setValue('country', user.country);
            methods.setValue('agreement_accepted', user.agreement_accepted);
        }
    }, [user])

    const onSubmit = methods.handleSubmit(async (data) => {
        try{
            const requestData: any = {};
            for (const [key, value] of Object.entries(data)) {
                if (user[key] !== undefined && user[key] !== value || (key === 'password' && value.toString().length > 0)) {
                    if (key === 'birth' && value === '') {
                        methods.setError('birth', {
                            type: 'server',
                            message: 'Дата не может быть пустой'
                        });
                        return;
                    }
                    requestData[key] = value;
                }
            }

            await updateProfile(requestData, userStore.getJWT());
        } catch(e: any) {
            const error = e.response.data?.error;
            if (error && error.message ) {
                methods.setError('isAlreadyExists', {
                    type: 'server',
                    message: error.message
                });
            } else {
                methods.setError('id', {
                    type: 'server',
                    message: 'Изменения не сохранены'
                });
            }
        }
    });

    function closeError(key: any) {
        methods.clearErrors(key);
    }

    return (
        <div>
            <>
                {(Object.keys(methods.formState.errors) as (keyof typeof methods.formState.errors)[]).map((key) => {
                    return methods.formState.errors?.[key] &&
                    methods.formState.errors?.[key]!.message &&
                    <FlashAlert key={key} message={methods.formState.errors?.[key]!.message} close={() => closeError(key)}/>
                })}
            </>
            
            <div className='container layout'>
                <div className='row row-mg0'>
                    <div className='col-sm-12 content content-full content-left'>
                        <div className='heading'>
                            <h1>Профиль</h1>
                        </div>
                        <div className='profile'>
                            <FormProvider {...methods} >
                                <form onSubmit={onSubmit} id='accountForm'>
                                    <div className='cfx'>
                                        <div className='l'>
                                            <UserInfo user={user} countries={countries} />
                                            <AccountSettings user={user} />
                                            <Agreements user={user} />
                                        </div>
                                        <div className='l'>
                                            <DeliveryInfo user={user} countries={countries} />
                                        </div>
                                    </div>
                                    <p className="profilesubmit">
                                        <input type="submit" name="useAccount" value="Сохранить" className="btn btn-primary btn-block" />
                                    </p>
                                </form>
                            </FormProvider>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UserProfilePage;
