import {createContext, useContext} from 'react';
import BonusesStore from './BonusesStore/BonusesStore';
import CartStore from './CartStore/CartStore';
import CategoriesStore from './CategoriesStore/CategoriesStore';
import FilterStore from './FilterStore/FilterStore';
import JerseyConstructorStore from './JerseyConstructorStore/JerseyConstructorStore';
import OrderStore from './OrderStore/OrderStore';
import ProductStore from './ProductStore/ProductStore';
import UserStore from './UserStore/UserStore';

type Store = {
    categoriesStore: CategoriesStore,
    userStore: UserStore,
    cartStore: CartStore,
    orderStore: OrderStore,
    filterStore: FilterStore,
    productStore: ProductStore,
    bonusStore: BonusesStore,
    jerseyConstructorStore: JerseyConstructorStore
}

const categoriesStore = new CategoriesStore();
const userStore = new UserStore();
const jerseyConstructorStore = new JerseyConstructorStore(userStore);
const bonusStore = new BonusesStore(userStore);
const cartStore = new CartStore(userStore, bonusStore, jerseyConstructorStore);
const orderStore = new OrderStore(userStore, cartStore, bonusStore);
const filterStore = new FilterStore(categoriesStore);
const productStore = new ProductStore(filterStore);

export const store: Store = {
    categoriesStore: categoriesStore,
    userStore: userStore,
    cartStore: cartStore,
    orderStore: orderStore,
    filterStore: filterStore,
    productStore: productStore,
    bonusStore: bonusStore,
    jerseyConstructorStore: jerseyConstructorStore
}

export const StoreContext = createContext(store);

export function useStore() {
    return useContext(StoreContext);
}
