import { makeAutoObservable } from 'mobx'
import { Category, Subcategory } from '../../types/ResponseTypes';

class CategoriesStore {
    constructor() {
        makeAutoObservable(this);

        this.categories = [];
    }

    categories: Array<Category>

    getCategoryAndSubcategoryIds(locationPath: string) {
        const category = this.categories.find((value) => value.url === locationPath);
        if (category) {
            return {
                name: category.display_name || category.name,
                type: 'category',
                id: category.id
            }
        }
        for (var i = 0; i < this.categories.length; i++) {
            const subcategory = this.categories[i].subcategories.find((sub) => sub.url === locationPath);
            if (subcategory) {
                return {
                    name: subcategory.display_name || subcategory.name,
                    type: 'subcategory',
                    id: subcategory.id
                }
            }
        }
        return {
            name: '',
            type: '',
            id: ''
        }
    }

    getSubcategoriesByPath(locationPath: string): Array<Subcategory> {
        const category = this.categories.find((value) => value.url === locationPath);
        if (category) {
            return category.subcategories;
        }
        for (var i = 0; i < this.categories.length; i++) {
            const subcategory = this.categories[i].subcategories.find((sub) => sub.url === locationPath);
            if (subcategory) {
                return this.categories[i].subcategories;
            }
        }
        return [];
    }
}

export default CategoriesStore;
