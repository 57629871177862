import { Link } from 'react-router-dom';
import { useStore } from '../../../store/store';
import { observer } from 'mobx-react-lite';

const CartBox = observer(() => {
    const { cartStore } = useStore();

    return (
            <Link className='cart' to='/cart/content'>
                <div className='cart-ico'>
                    <div className='badge p-0'>{cartStore.cartItems.length}</div>
                </div>
                <div className='cart-price'>
                    {cartStore.getTotal()} P
                </div>
            </Link>
    )
})

export default CartBox;
