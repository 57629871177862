import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createBrowserHistory } from 'history';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

const replaceHashPath = () => {
    const history = createBrowserHistory();
    const hash = history.location.hash;
    if (hash) {
      const path = hash.replace(/^#/, '');
      if (path) {
        history.replace(path);
      }
    }
}
replaceHashPath();

root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
