import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useStore } from '../../../store/store';
import { PaymentMethod } from '../../../types/ResponseTypes';

const PaymentMethods = observer(() => {
    const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]|undefined>()
    const { orderStore } = useStore();
    
    useEffect(() => {
        const getData = async () => {
            const data = await orderStore.loadPaymentMethods()
            setPaymentMethods(data)
        } 

        getData();
    }, [])

    return (
        <div className='payment'>
            { paymentMethods && <h2><label>Оплата</label></h2> }
            { paymentMethods && paymentMethods.map(item => {
                return (
                    <div className='item' key={item.id}>
                        <label>
                            <input 
                                type='radio' 
                                name='id_payment' 
                                id={ `frm-deliveryForm-id_payment-${item.id}` } 
                                value='{ item.id }' 
                                checked={ item.id == orderStore.currentOrder.payment_method } 
                                onChange={(e) => {
                                    orderStore.currentOrder.payment_method = item.id
                                }}
                              />
                            { item.name }
                        </label>
                        <div className='desc'>{ item.description }</div>
                    </div>
            )})}
        </div>
    );
})

export default PaymentMethods;


