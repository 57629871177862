import { ReactElement, useEffect, useState } from 'react';
import { useForm, useFormContext } from 'react-hook-form';
import { getPointOfIssueOfOrders } from '../../../api/LayoutApi';
import { useStore } from '../../../store/store';
import { DpdDeliveryPoint } from '../../../types/ResponseTypes';

function Dpd(): ReactElement {
    const [cityName, setCityName] = useState<string>('');
    const [dpdOptions, setDpdOptions] = useState<Array<DpdDeliveryPoint>>([]);
    const { register, setValue, setError } = useFormContext();
    const { userStore } = useStore();

    useEffect(() => {
        if (dpdOptions.length > 0 && dpdOptions[0]) {
            setValue('desired_delivery_point', dpdOptions[0].fullAddress);
        }
    }, [dpdOptions]);

    async function onClick() {
        if (cityName.length < 1) {
            setError('cityName',{
                type: 'server',
                message: 'Пожалуйста, введите название города полностью'
            });
            return;
        }

        const resp = await getPointOfIssueOfOrders(cityName, userStore.getJWT());
        setDpdOptions(resp.data);
    }

    function changeDeliveryPoint(e: React.ChangeEvent<HTMLSelectElement>) {
        setValue('desired_delivery_point', e.target.options[e.target.selectedIndex].text);
    }

    return (
        <fieldset>
            <label htmlFor='delivery'>
                Информация о доставке
            </label>
            <div id='deliveryDiv'>
                <p>
                    <label htmlFor='frm-invoiceForm-delivery_name'>Имя</label> 
                    <input id='frm-invoiceForm-delivery_name' {...register('delivery_name')} />
                </p>
                <p>
                    <label htmlFor='frm-invoiceForm-delivery_city'>Город</label> 
                    <input id='frm-invoiceForm-delivery_city' value={cityName} onChange={(e) => setCityName(e.target.value)} />
                </p>
                <div className='custom-get-gop'>
                    <span className='inline-btn'>
                        <input type='button' onClick={() => {onClick()}} value='Получить список пунктов выдачи' className='btn btn-secondary' />
                    </span>
                </div>
                {
                    dpdOptions.length > 0 &&
                    <p>
                        <label htmlFor='frm-invoiceForm-delivery_point'>Пункт выдачи</label> 
                        <select
                            {...register('delivery_point', {required: 'Пожалуйста, выберите пункт выдачи', minLength: 1})}
                            id='frm-invoiceForm-delivery_point'
                            onChange={changeDeliveryPoint}
                        >
                            {dpdOptions.map((point) => (
                                <option key={point.pointCode} value={point.cityId}>
                                    {point.fullAddress}
                                </option>
                            ))}
                        </select>
                    </p>
                }
            </div>
        </fieldset>
    );
}

export default Dpd;
