import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import { useStore } from '../../../store/store';
import { CartItem } from '../../../types/ResponseTypes';
import CartTableRow from '../CartTableRow/CartTableRow';

const CartTable = observer(() => {
    const { cartStore } = useStore();
    const history = useNavigate();

    async function deleteProduct(cartItem: CartItem) {
        try {
            await cartStore.deleteItem(cartItem);
            if (cartStore.getCountItems() === 0) {
                history('/');
            }
        } catch (e) {
            // TODO: Do something, if backend returns error
        }
    }

    return (
        <table className='table'>
            <tbody>
                <tr>
                    <th colSpan={2}>Товар</th>
                    <th className='center'>НДС</th>
                    <th className='center'>Стоимость за шт.</th>
                    <th className='center'>Количество</th>
                    <th className='center'>Итого</th>
                    <th></th>
                </tr>
                {
                    cartStore.cartItems && cartStore.cartItems?.map((cartItem: CartItem) => <CartTableRow deleteProduct={deleteProduct} key={cartItem.id} cartItem={cartItem} />)
                }
            </tbody>
        </table>
    );
})

export default CartTable;
