import { runInAction, makeAutoObservable, toJS } from 'mobx'
import { NavigateFunction } from 'react-router-dom';
import { getProductPrices } from '../../api/LayoutApi';
import { searchPagePrefix } from '../../constants/Constants';
import { FilterData, SortData } from '../../types/ResponseTypes';
import CategoriesStore from '../CategoriesStore/CategoriesStore';

class FilterStore {
    constructor(categoriesStore: CategoriesStore) {
        makeAutoObservable(this);

        this.categoriesStore = categoriesStore;
        this.filters = [];
        this.min = 0;
        this.max = 1;
        this.priceValues = [0, 1];
        this.categoryFilter = null;
        this.searchFilter = null;
        this.sort = [{
            index: 0,
            field: 'common_name',
            ordering: 'asc'
        }]
    }

    categoriesStore: CategoriesStore;
    filters: Array<FilterData>;
    categoryFilter?: FilterData | null;
    searchFilter?: FilterData | null;
    min: number;
    max: number;
    priceValues: Array<number>
    sort: Array<SortData>

    async initFilter(path?: string, isCategoryFilterNeeded: boolean = true) {
        if (path) {
            if (path.startsWith(searchPagePrefix)) {
                const key = path.substring(searchPagePrefix.length);
                this.filters = [];
                this.categoryFilter = null;
                this.searchFilter = {
                    pathToField: ['common_name'],
                    operator: '$containsi',
                    value: key
                };
            } else {
                this.searchFilter = null;
                this.filters = [];
                const { type, id } = this.categoriesStore.getCategoryAndSubcategoryIds(path);
                this.categoryFilter = isCategoryFilterNeeded ? {
                    pathToField: [type, 'id'],
                    operator: '$eq',
                    value: id
                } : null;
            }

            const allFilters = this.getAllFilters();
            if (allFilters.length > 0 || !isCategoryFilterNeeded) {
                const resp = await getProductPrices(allFilters);
                
                runInAction(() => {
                    this.min = resp.data.min;
                    this.max = resp.data.max;
                    this.priceValues = [resp.data.min, resp.data.max];
                });
            }
        } else {
            this.filters = [];
            this.categoryFilter = null;
            this.searchFilter = null;
            this.min = 0;
            this.max = 1;
            this.priceValues = [0, 1];
        }
    }

    getAllFilters() {
        let allFilters: Array<FilterData> = [];
        if (this.filters) {
            allFilters = allFilters.concat(toJS(this.filters));
        }
        if (this.categoryFilter) {
           allFilters.push(toJS(this.categoryFilter));
        }
        if (this.searchFilter) {
            allFilters.push(toJS(this.searchFilter));
        }
        return allFilters.filter(x => !x.pathToField.includes(''));
    }

    addFilter(filter: FilterData) {
        this.filters.push(filter);
    }

    changeFilters(filters: Array<FilterData>) {
        this.filters = filters;
    }

    clearFilters() {
        this.filters = [];
    }

    setCategoryFilter(filter: FilterData) {
        this.categoryFilter = filter;
    }

    setSearchFilter(filter: FilterData) {
        this.searchFilter = filter;
    }

    doSearch(key: string, navigate: NavigateFunction) {
        this.clearFilters();
        this.categoryFilter = null;
        this.searchFilter = {
            pathToField: ['common_name'],
            operator: '$containsi',
            value: key
        }
        navigate(`${searchPagePrefix}${key}`);
    }

    changeSort(sort: SortData) {
        this.sort = [sort];
    }
}

export default FilterStore;
