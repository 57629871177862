import { ReactElement, useEffect, useState } from "react";
import { Product } from "../../../types/ResponseTypes";

type StickerProps = {
    product: Product
}

function Sticker({ product }: StickerProps): ReactElement {
    const [text, setText] = useState<string>('');
    const [style, setStyle] = useState<string>('');

    useEffect(() => {
        if (product.novelty) {
            setText('Новинка');
            setStyle('sticker-green');
            return;
        }

        if (product.discount) {
            setText('Скидка');
            setStyle('sticker-blue');
        }

        if (product.partial_discount) {
            setText('Скидка на размер/цвет');
            // TODO: use special sticker style when decided
            setStyle('sticker-blue');
        }
    }, [product]);

    return (
        <div className="stickers">
            <div className={style}>{text}</div>
        </div>
    );
}

export default Sticker;
