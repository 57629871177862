import { ReactElement } from 'react';
import { OrderDetailDto, OrderItem } from '../../../types/ResponseTypes';

type OrderItemProps = {
    order: OrderDetailDto,
    item: OrderItem
}

function OrderItemElement({ item, order }: OrderItemProps): ReactElement {

    function getProductPrice() {
        return (item.position_price / (item.amount || 1)).toFixed(2);
    }

    function productName() {
        return `${item.product.name} ${item.customisation || ''}`;
    }

    return (
        <tr>
            <td className='left'>{productName()}</td>
            <td className='center'>{order.tax.percent_value}</td>
            <td className='center'>{getProductPrice()} P</td>
            <td className='center'>{item.amount} шт</td>
            <td className='center'>{item.position_price} P</td>
        </tr>
    );
}

export default OrderItemElement;
