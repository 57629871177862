import { useEffect, useState } from 'react';
import { useStore } from '../../store/store';
import { runInAction } from 'mobx';
import Pipe from '../OrderPipe/OrderPipe';
import DeliveryMethods from './DeliveryMethods/DeliveryMethods';
import { observer } from 'mobx-react';
import { Link, useNavigate } from 'react-router-dom';
import PaymentMethods from './PaymentMethods/PaymentMethods';
import FlashAlert from '../FlashAlert/FlashAlert';
import Dpd from '../InvoiceDataPage/Dpd/Dpd';
import Cdek from '../InvoiceDataPage/Cdek/Cdek';
import RussianPost from '../InvoiceDataPage/RussianPost/RussianPost';
import {
  getCountries,
  getDeliveryPrice,
  getFreeDeliveryPromotion,
  getFreeHomeDeliveryPromotion,
} from '../../api/LayoutApi';
import { mapBaseAndBaseCollectionResponse } from '../../mapping/ResponseMapping';
import { FormProvider, useForm } from 'react-hook-form';
import FromHeaderErrors from '../FormHeaderErrors/FormHeaderErrors';
import { CDEK_PACKAGE_PARAMETERS } from '../../constants/Constants';

const DeliveryPaymentPage = observer(() => {
  const { orderStore, userStore } = useStore();
  const [error, setError] = useState('');
  const [freeDeliveryValue, setFreeDeliveryValue] = useState<number | null>();
  const [freeHomeDeliveryValue, setFreeHomeDeliveryValue] = useState<
    number | null
  >();
  const [delivery, setDelivery] = useState<boolean>(false);
  const [countries, setCountries] = useState([]);
  const navigate = useNavigate();
  const methods = useForm();

  useEffect(() => {
    orderStore.loadCurrentOrder();
  }, []);

  useEffect(() => {
    const getData = async () => {
      const response = await getCountries();
      setCountries(mapBaseAndBaseCollectionResponse(response));
    };

    getData();
  }, []);

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await getFreeDeliveryPromotion(userStore.getJWT());
        if (response.data.data?.attributes?.sum) {
          setFreeDeliveryValue(response.data.data.attributes.sum);
        } else {
          setFreeDeliveryValue(null);
        }
      } catch (error: any) {
        setFreeDeliveryValue(null);
      }
    };

    getData();
  }, []);

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await getFreeHomeDeliveryPromotion(userStore.getJWT());
        if (response.data.data?.attributes?.sum) {
          setFreeHomeDeliveryValue(response.data.data.attributes.sum);
        } else {
          setFreeHomeDeliveryValue(null);
        }
      } catch (error: any) {
        setFreeHomeDeliveryValue(null);
      }
    };

    getData();
  }, []);

  const onSubmit = async (data: any) => {
    if (
      !data.delivery_point &&
      !data.delivery_postcode && // для доставки СДЭК до двери код города не приходит; дальше по приоритетам индекс,
      !data.delivery_address && // адрес
      (orderStore.currentOrder.delivery_method.id === 1 ||
        orderStore.currentOrder.delivery_method.id === 2)
    ) {
      methods.setError('delivery_point', {
        type: 'server',
        message:
          'Пожалуйста, дождитесь списка пунктов выдачи заказов и выберите нужный, либо загрузки карты и выберите адрес',
      });
      return;
    }

    if (orderStore.currentOrder.delivery_method.id !== 4) {
      await calculateDeliveryPrice(data);
    } else {
      orderStore.currentOrder.original_delivery_cost = 0;
      orderStore.currentOrder.real_delivery_cost = 0;
    }

    if (
      orderStore.currentOrder.delivery_method.id === 1 ||
      orderStore.currentOrder.delivery_method.id === 2
    ) {
      orderStore.currentOrder.delivery_filled = data.hasOwnProperty(
        'delivery_filled'
      )
        ? data.delivery_filled == true ||
          data.delivery_filled?.toString()?.toLowerCase() == 'true'
        : !!data.delivery_name || false;
    } else {
      orderStore.currentOrder.delivery_filled = delivery || false;
    }

    orderStore.currentOrder.delivery_point = data.delivery_point;
    orderStore.currentOrder.desired_delivery_point =
      data.desired_delivery_point;

    orderStore.currentOrder.delivery_name = data.delivery_name;
    orderStore.currentOrder.delivery_street = data.delivery_street;
    orderStore.currentOrder.delivery_building_number =
      data.delivery_building_number;
    orderStore.currentOrder.delivery_flat_number = data.delivery_flat_number;
    orderStore.currentOrder.delivery_city = data.delivery_city;
    orderStore.currentOrder.delivery_postcode = data.delivery_postcode;
    orderStore.currentOrder.delivery_address = data.delivery_address;
    orderStore.currentOrder.delivery_country = Number(data.delivery_country);

    orderStore.saveCurrentOrderToLocalStore();

    if (orderStore.isDeliveryAndPaymentReady()) {
      orderStore.saveCurrentOrderToLocalStore();

      navigate('/cart/recapitulation');
    } else {
      if (orderStore.currentOrder.delivery_method.id <= 0) {
        setError('Пожалуйста, выберите способ доставки');
      } else if (orderStore.currentOrder.payment_method <= 0) {
        setError('Пожалуйста, выберите способ оплаты');
      }
    }
  };

  const onErrorClose = () => {
    setError('');
  };

  async function calculateDeliveryPrice(data: any) {
    try {
      let codeTo;
      const deliveryPostcode =
        data.delivery_postcode ||
        data.payment_postcode ||
        orderStore.currentOrder.delivery_postcode ||
        orderStore.currentOrder.payment_postcode;
      orderStore.currentOrder.delivery_postcode = deliveryPostcode;
      const deliveryCountry =
        data.delivery_country || orderStore.currentOrder.delivery_country;
      orderStore.currentOrder.delivery_country = deliveryCountry;
      const deliveryStreet =
        data.delivery_street || orderStore.currentOrder.delivery_street;
      orderStore.currentOrder.delivery_street = deliveryStreet;
      const deliveryCity =
        data.delivery_city || orderStore.currentOrder.delivery_city;
      orderStore.currentOrder.delivery_city = deliveryCity;
      const deliveryAddress =
        data.delivery_address || orderStore.currentOrder.delivery_address;
      orderStore.currentOrder.delivery_address = deliveryAddress;

      if (orderStore.currentOrder.delivery_method.id === 1) {
        codeTo = data.delivery_point;
      } else if (orderStore.currentOrder.delivery_method.id === 3) {
        codeTo = deliveryPostcode;
      } else {
        codeTo = data.delivery_point;
      }
      const deliveryId = orderStore.currentOrder.delivery_method.id;
      const weight =
        orderStore.currentOrder.delivery_method.id === 2 ||
        orderStore.currentOrder.delivery_method.id === 3
          ? CDEK_PACKAGE_PARAMETERS.weight
          : undefined;
      const length =
        orderStore.currentOrder.delivery_method.id === 2
          ? CDEK_PACKAGE_PARAMETERS.length
          : undefined;
      const width =
        orderStore.currentOrder.delivery_method.id === 2
          ? CDEK_PACKAGE_PARAMETERS.width
          : undefined;
      const height =
        orderStore.currentOrder.delivery_method.id === 2
          ? CDEK_PACKAGE_PARAMETERS.height
          : undefined;
      const tariff =
        orderStore.currentOrder.delivery_method.id === 2 ||
        orderStore.currentOrder.delivery_method.id === 3
          ? data.tariff
          : undefined;
      const address = orderStore.currentOrder.delivery_address;
      const deliveryPriceResponse = await getDeliveryPrice(
        codeTo,
        deliveryId,
        deliveryCountry,
        userStore.getJWT(),
        weight,
        length,
        width,
        height,
        deliveryPostcode,
        address,
        tariff
      );

      orderStore.currentOrder.desired_delivery_point =
        data.desired_delivery_point ||
        orderStore.currentOrder.desired_delivery_point;
      const deliveryPoint = orderStore?.currentOrder?.desired_delivery_point;

      orderStore.currentOrder.original_delivery_cost =
        deliveryPriceResponse?.data;

      if (
        freeDeliveryValue &&
        orderStore.cartStore.getTotal() >= freeDeliveryValue &&
        !deliveryPoint?.includes('Курьер:')
      ) {
        orderStore.currentOrder.real_delivery_cost = 0;
      } else if (
        freeHomeDeliveryValue &&
        orderStore.cartStore.getTotal() >= freeHomeDeliveryValue &&
        !deliveryPoint?.includes('Курьер:')
      ) {
        if (data.delivery_city) {
          if (
            data.delivery_city.toLowerCase() === 'ярославль' ||
            data.delivery_city?.toLowerCase()?.includes('г ярославль') ||
            data.delivery_city?.toLowerCase()?.includes('г. ярославль')
          ) {
            orderStore.currentOrder.real_delivery_cost = 0;
          } else {
            orderStore.currentOrder.real_delivery_cost =
              deliveryPriceResponse?.data;
          }
        } else {
          if (
            orderStore.currentOrder.payment_city &&
            (orderStore.currentOrder.payment_city.toLowerCase() ===
              'ярославль' ||
              orderStore.currentOrder.payment_city
                ?.toLowerCase()
                ?.includes('г ярославль') ||
              orderStore.currentOrder.payment_city
                ?.toLowerCase()
                ?.includes('г. ярославль'))
          ) {
            orderStore.currentOrder.real_delivery_cost = 0;
          } else {
            orderStore.currentOrder.real_delivery_cost =
              deliveryPriceResponse?.data;
          }
        }
      } else {
        orderStore.currentOrder.real_delivery_cost =
          deliveryPriceResponse?.data;
      }
      orderStore.updatePrices();
    } catch (err: any) {
      runInAction(() => {
        orderStore.currentOrder.original_delivery_cost = 0;
        orderStore.currentOrder.real_delivery_cost = 0;
      });
    }
  }

  return (
    <div className='container layout'>
      <main className='row'>
        <div className='col-sm-12 content content-full content-left'>
          {error && <FlashAlert message={error} close={onErrorClose} />}
          <FromHeaderErrors
            errors={methods.formState.errors}
            clearErrors={methods.clearErrors}
          />
          <div className='cart-heading'>
            <Pipe />
          </div>
          <div className='cart'>
            <div className='deliverypayment'>
              <div id='snippet--deliverySnippet'>
                <FormProvider {...methods}>
                  <form onSubmit={onSubmit} method='POST'>
                    <div className='l'>
                      <DeliveryMethods />
                      <PaymentMethods />
                    </div>

                    <div className='l'>
                      {orderStore.currentOrder.delivery_method.id === 1 && (
                        <Dpd />
                      )}
                      {orderStore.currentOrder.delivery_method.id === 2 && (
                        <Cdek />
                      )}
                      {orderStore.currentOrder.delivery_method.id === 3 && (
                        <RussianPost
                          countries={countries}
                          delivery={delivery || false}
                          setDelivery={setDelivery}
                        />
                      )}
                    </div>
                    <div className='navigation cfx'>
                      <div className='l'>
                        <Link
                          className='inline-btn btn btn-secondary'
                          to='/cart/invoice-data'
                        >
                          <span>◀ Данные заказа</span>
                        </Link>
                      </div>
                      <div className='r'>
                        <span className='inline-btn'>
                          <input
                            type='button'
                            onClick={async (e) => {
                              methods.clearErrors();
                              const result = methods.handleSubmit(
                                onSubmit,
                                onSubmit
                              );
                              await result(e);
                            }}
                            value='Проверка заказа ▶'
                            className='btn btn-secondary'
                          />
                        </span>
                      </div>
                    </div>
                  </form>
                </FormProvider>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
});

export default DeliveryPaymentPage;
