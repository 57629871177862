import { ReactElement, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { login, whoAmI } from '../../api/LayoutApi';
import { Link } from 'react-router-dom';
import { useStore } from '../../store/store';
import FlashAlert from '../FlashAlert/FlashAlert';

type Inputs = {
    identifier: string,
    password: string
};

function LoginPage(): ReactElement {
    const { register, handleSubmit, setError, formState: { errors }, clearErrors } = useForm<Inputs>();
    const [ searchParams, setSearchParams ] = useSearchParams();
    const [isRecovered, setIsRecovered] = useState<boolean>(false);
    const { userStore, cartStore } = useStore();
    const history = useNavigate();

    useEffect(() => {
        const isRecoveredParam = searchParams.get('isRecovered') === 'true';
        setIsRecovered(isRecoveredParam);
    }, []);

    const onSubmit = handleSubmit(async (data) => {
        try {
            const response = await login(data);
            if (response.data.jwt) {
                await userStore.login(response.data.jwt);
                await cartStore.loadCartInfo();
                history('/');
            }
        } catch(e: any) {
            const error = e.response.data?.error;
            if (error && error.message) {
                setError('password', {
                    type: 'server',
                    message: error.message === 'Invalid identifier or password' ? 'Неверный логин или пароль' : 'Вход не выполнен'
                });
            } else {
                setError('password', {
                    type: 'server',
                    message: 'Вход не выполнен'
                });
            }
        }
    });

    function closeError() {
        clearErrors('password');
    }

    
    function closeFlashGreenAlert() {
        setIsRecovered(false);
    }

    return (
        <div>
            {
                errors.password &&
                <FlashAlert message={errors.password?.message} close={closeError} /> 
            }
            { 
                isRecovered &&
                <FlashAlert message={'Пароль был успешно обновлен'} close={closeFlashGreenAlert} isGreen={true} />
            }
            <div className='container layout'>
                <main className='row row-mg0'>
                    <div className='col-sm-12 content content-full content-left'>
                        <div className='heading center'>
                            <h1>Войти</h1>
                        </div>
                        <div className='login'>
                            <form onSubmit={onSubmit} method='post' id='loginForm'>
                                <div className='form-part username'>
                                    <label>Логин</label>
                                    <input type='text' {...register('identifier')} id='frm-loginForm-username' />
                                </div>
                                <div className='form-part password'>
                                    <label>Пароль</label>
                                    <input type='password' {...register('password')} id='frm-loginForm-password' />
                                </div>
                                <input type='submit' name='login' value='Войти' className='btn btn-secondary' />
                            </form>
                            <br />
                            <Link title='Регистрация' to='/registration'>Регистрация</Link>
                            <span className='del'> | </span>
                            <Link title='Забыли пароль?' to='/login/lost-password'>Забыли пароль?</Link>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    );
}

export default LoginPage;
