import React, { ReactElement, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { thereAreOnly } from '../../../constants/Constants';
import { mapProductToCartItem } from '../../../mapping/ResponseMapping';
import { useStore } from '../../../store/store';
import { CartItem, Product } from '../../../types/ResponseTypes';
import ProductCustomisation from '../ProductCustomisation/ProductCustomisation';

type ProductCartBoxProps = {
  setErrorMessage: Function;
  product?: Product;
  productsInGroup?: Array<Product>;
  onVariationChange?: (x: Product) => void;
};

function ProductCartBox({
  setErrorMessage,
  product,
  productsInGroup,
  onVariationChange,
}: ProductCartBoxProps): ReactElement {
  const [amount, setAmount] = useState<number>(1);
  const [selectedProduct, setSelectedProduct] = useState<Product | undefined>();
  const [bonusesWillBeAdded, setBonusesWillBeAdded] = useState<number>(0);
  const [name, setName] = useState<string>('');
  const [number, setNumber] = useState<string>('');
  const { cartStore, bonusStore } = useStore();
  const history = useNavigate();

  useEffect(() => {
    setSelectedProduct(product);
    setBonusesWillBeAdded(getCountBonuses());
  }, [product]);

  function changeCount(e: React.ChangeEvent<HTMLInputElement>) {
    if (Number(e.target.value)) {
      setAmount(Number(e.target.value));
    }
  }

  function changeSelectedElement(e: React.ChangeEvent<HTMLSelectElement>) {
    const newVariation = JSON.parse(e.target.value);
    setSelectedProduct(newVariation);
    onVariationChange && onVariationChange(newVariation);
  }

  async function addToCart() {
    if (!product) {
      return;
    }

    const productsInCart = cartStore.cartItems.filter(
      (item) => item.product.id === product.id
    );
    const amountItemsInCart = productsInCart.reduce(
      (accumulator, currentValue) => {
        return accumulator + currentValue.amount;
      },
      0
    );
    if (
      selectedProduct &&
      selectedProduct.availability < (amountItemsInCart + amount || amount)
    ) {
      alert(thereAreOnly(selectedProduct.availability));
      return;
    }

    const customisation =
      name || number
        ? {
            pennant_name: name,
            pennant_number: number,
          }
        : undefined;

    if (number && !/^\d{1,3}$/.test(number)) {
      setErrorMessage('В номере указаны недопустимые символы');
      return;
    }

    if (name && !/^[a-zA-Zа-яА-Я ёЁ]+$/.test(name)) {
      setErrorMessage('В имени указаны недопустимые символы');
      return;
    }

    const data: CartItem = mapProductToCartItem(
      selectedProduct || product,
      (selectedProduct?.price || product.price) * amount,
      customisation
    );
    data.amount = amount;

    try {
      await cartStore.addItem(data);
      history('/cart/content');
    } catch (e: any) {
      const error = e.response.data?.error;
      if (
        error &&
        error.message.includes('Доступно только') &&
        error.message.includes('штук в магазине.')
      ) {
        const regexp = /\d*/g;
        const avaliableCount = error.message
          .match(regexp)
          .filter((group: any) => !!group);
        alert(thereAreOnly(avaliableCount));
      }
    }
  }

  function getOptions() {
    return productsInGroup?.map((element) => (
      <option key={element.id} value={JSON.stringify(element)}>
        {element.name}
      </option>
    ));
  }

  function getCountBonuses() {
    if (!bonusStore.allowBonusAdding) {
      return 0;
    }
    if (product?.price) {
      return product?.price * 0.05;
    }
    return 0;
  }

  return (
    <form id='addToCartForm'>
      <div className='form-box'>
        <label>Количество</label>
        <div className='count-box'>
          <input
            type='text'
            size={3}
            id='frm-addToCartForm-count'
            onChange={(e) => changeCount(e)}
            value={amount}
          />
          <a
            id='plus'
            onClick={() => {
              amount < 50 && setAmount((value) => value + 1);
            }}
          >
            +
          </a>
          <a
            id='minus'
            onClick={() => {
              amount > 1 && setAmount((value) => value - 1);
            }}
          >
            -
          </a>
        </div>
      </div>
      {productsInGroup && productsInGroup.length > 0 && (
        <div className='form-box'>
          <label>Вариант</label>
          <select
            className='changeVariantSB'
            defaultValue={JSON.stringify(product)}
            onChange={(e) => changeSelectedElement(e)}
          >
            {getOptions()}
          </select>
          {product?.common_customisable && (
            <ProductCustomisation setName={setName} setNumber={setNumber} />
          )}
        </div>
      )}
      <div className='product-price' id='pricevalue'>
        {product?.price} Р
        {product?.discount && product?.default_price && (
          <span>{product.default_price}</span>
        )}
      </div>
      {bonusesWillBeAdded > 0 && (
        <div className='custom-product-bonuses'>
          Будет начислено {bonusesWillBeAdded} бонусов
        </div>
      )}
      <input
        type='button'
        className='btn btn-primary'
        value='В корзину'
        onClick={addToCart}
      />
    </form>
  );
}

export default ProductCartBox;
