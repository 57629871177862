import { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { useStore } from '../../store/store';

function OrderPipe(): ReactElement {
    const { orderStore, cartStore } = useStore();

    return (
        <div className="pipe">
            {cartStore.cartItems.length > 0 ? <Link to="/cart/content">Содержимое корзины</Link> : <span>Содержимое корзины</span>}
            <span className="del"> &gt; </span>
            {orderStore.isInvoiceReady() ? <Link to="/cart/invoice-data">Данные заказа</Link> : <span>Данные заказа</span> }
            <span className="del"> &gt; </span>
            {orderStore.isDeliveryAndPaymentReady() ? <Link to="/cart/delivery-payment">Доставка и оплата</Link> : <span>Доставка и оплата</span> }
            <span className="del"> &gt; </span>
            <span>Проверка заказа</span>
            <span className="del"> &gt; </span>
            <span>Завершение заказа</span>
        </div>
    );
}

export default OrderPipe;


