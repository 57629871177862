import { ReactElement, useEffect } from 'react';
import { useStore } from '../../store/store';

function SuccessesPaymentPage(): ReactElement {
    const {bonusStore} = useStore();

    useEffect(() => {
        bonusStore.loadBonusInfo();
    }, []);

    return (
        <div className='container layout'>
            <main className='row'>
                <div className='col-sm-12 content content-full content-left'>
                    <div className='heading'><h1>Платежное поручение</h1></div>
                    <div className='account'>
                        <p>Оплата прошла успешно. Спасибо!</p>
                    </div>
                </div>
            </main>
        </div>
    );
}

export default SuccessesPaymentPage;
