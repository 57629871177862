import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { Category, Subcategory } from '../../../types/ResponseTypes';

type CategoryMenuProps = {
  categories: Array<Category>;
  menuRef: React.RefObject<HTMLDivElement>;
};

function CategoryMenu({
  categories,
  menuRef,
}: CategoryMenuProps): ReactElement {
  function getSubcategoryLi(subcategory: Subcategory) {
    if (!subcategory?.url) {
      console.log(`No category url for ${subcategory?.name}`);
    }

    return (
      <li key={subcategory.id}>
        <Link to={subcategory.url || '/catalog'}>
          {subcategory.display_name}
        </Link>
      </li>
    );
  }

  function getCategoryLi(category: Category) {
    if (!category?.url) {
      console.log(`No category url for ${category?.name}`);
    }

    return (
      <li className='dropdown my-custom-dropdown' key={category.id}>
        <Link to={category.url || '/catalog'}>
          <div>{category.name}</div>
        </Link>
        {category.subcategories.length > 0 ? (
          <ul className='dropdown-menu'>
            {category.subcategories?.map((el) => getSubcategoryLi(el))}
          </ul>
        ) : undefined}
      </li>
    );
  }

  return (
    <div ref={menuRef} className='row nav-row' id='navbar'>
      <ul className='nav custom-nav'>
        {categories?.map((el) => getCategoryLi(el))}
      </ul>
    </div>
  );
}

export default CategoryMenu;
