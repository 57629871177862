import { ReactElement, useEffect, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { mapBaseAndBaseCollectionResponse } from '../../mapping/ResponseMapping';
import { getCountries, registration } from '../../api/LayoutApi';
import UserInfo from './UserInfo/UserInfo';
import AccountSettings from './AccountSettings/AccountSettings';
import DeliveryInfo from './DeliveryInfo/DeliveryInfo';
import FlashAlert from '../FlashAlert/FlashAlert';
import Agreements from './Agreements/Agreements';
import { useStore } from '../../store/store';

type Inputs = {
    name: string,
    surname: string,
    patronymic: string,
    email: string,
    phone: string,
    birth: Date | undefined,
    username: string,
    password: string,
    password_verify: string,
    newsletter_subscribed: boolean,
    agreement_accepted: boolean,
    country: number,
    isAlreadyExists: boolean,
    server: boolean
};
  
const schema = yup.object().shape({
    name: yup.string().required('Пожалуйста, введите Имя'),
    surname: yup.string().required('Пожалуйста, введите Фамилию'),
    email: yup.string().required('Пожалуйста, введите Ваш email'),
    phone: yup.string().required('Пожалуйста, введите номер телефона'),
    username: yup.string().required('Пожалуйста, введите логин'),
    password: yup.string().required('Пожалуйста, введите пароль').min(6, 'Пароль должен содержать не менее 6 символов'),
    password_verify: yup.string().required('Пожалуйста, введите пароль для подтверждения')
        .oneOf([yup.ref('password'), null], 'Пароли должны совпадать'),
    agreement_accepted: yup.boolean().isTrue('Вы должны согласиться с условиями магазина')
});

function RegistrationPage(): ReactElement {    
    const [countries, setCountries] = useState<any>([]);
    const history = useNavigate()
    const { userStore, cartStore } = useStore();
    const methods = useForm<Inputs>({
        resolver: yupResolver(schema)
    });

    useEffect(() => {
        const getData = async () => {
            const response = await getCountries();
            setCountries(mapBaseAndBaseCollectionResponse(response));
        }
        getData();
    }, []);

    useEffect(() => {
        if (countries && countries.length > 0) {
            methods.setValue('country', countries[0].id);
        }
    }, [countries]);

    const onSubmit = methods.handleSubmit(async (data) => {
        try{
            if (!data.birth) {
                data.birth = undefined;
            }
            const response = await registration(data);
            if (response.data.jwt) {
                await userStore.login(response.data.jwt);
                await cartStore.loadCartInfo();
                history('/');
            }
        } catch(e: any) {
            const error = e.response.data?.error;
            if (error) {
                if (error.message === 'Email or Username are already taken') {
                    methods.setError('isAlreadyExists', {
                        type: 'server',
                        message: 'Пользователь с таким логином или email уже существует'
                    });
                } else {
                    methods.setError('server', {
                        type: 'server',
                        message: error?.message
                    });
                }
            } else {
                methods.setError('server', {
                    type: 'server',
                    message: 'Произошла ошибка. Пожалуйста попробуйте позже'
                });
            }
        }
    });

    function closeError(key: any) {
        methods.clearErrors(key);
    }

    return (
        <div>
            <>
                {(Object.keys(methods.formState.errors) as (keyof typeof methods.formState.errors)[]).map((key) => {
                    return methods.formState.errors?.[key] &&
                    methods.formState.errors?.[key]!.message &&
                    <FlashAlert key={key} message={methods.formState.errors?.[key]!.message} close={() => closeError(key)}/>
                })}
            </>
            
            <div className='container layout'>
                <div className='row row-mg0'>
                    <div className='col-sm-12 content content-full content-left'>
                        <div className='heading'>
                            <h1>Регистрация</h1>
                        </div>
                        <div className='registration'>
                            <FormProvider {...methods} >
                                <form onSubmit={onSubmit} id='registrationForm'>
                                    <div className='cfx'>
                                        <div className='l'>
                                            <UserInfo countries={countries} />
                                            <AccountSettings />
                                            <Agreements />
                                        </div>
                                        <div className='l'>
                                            <DeliveryInfo countries={countries} />
                                        </div>
                                    </div>
                                    <p className='regsubmit'>
                                        <input type='submit' name='registration' id='g-recaptcha' value='Создать аккаунт' className='btn btn-secondary' />
                                    </p>
                                </form>
                            </FormProvider>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default RegistrationPage;
