import { ReactElement, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { recoveryPassword } from '../../api/LayoutApi';
import FlashAlert from '../FlashAlert/FlashAlert';

type Inputs = {
    password: string,
    passwordConfirmation: string,
    code?: string
};

const schema = yup.object().shape({
    password: yup.string().required('Пожалуйста, введите пароль').min(6, 'Пароль должен содержать не менее 6 символов'),
    passwordConfirmation: yup.string().required('Пожалуйста, введите пароль для подтверждения')
        .oneOf([yup.ref('password'), null], 'Пароли должны совпадать'),
});

function RecoveryPasswordPage(): ReactElement {
    const [ searchParams, setSearchParams ] = useSearchParams();
    const [ isSuccess, setIsSuccess ] = useState<boolean>(false);
    const [ code, setCode ] = useState<string>('');
    const history = useNavigate();
    const { register, handleSubmit, setError, formState: { errors }, clearErrors } = useForm<Inputs>({
        resolver: yupResolver(schema),
    });

    useEffect(() => {
        const token = searchParams.get('code');
        setCode(token||'');
    }, []);

    const onSubmit = handleSubmit(async (data) => {
        try {
            const sentData = { ...data, code: code };
            const response = await recoveryPassword(sentData);
            if (response.data.jwt) {
                history('/login?isRecovered=true');
            }
        } catch(e: any) {
            const error = e.response.data?.error;
            if (error && error.message) {
                setError('password', {
                    type: 'server',
                    message: error.message === 'Incorrect code provided' ? 'Код недействительный' : 'Сброс пароля не выполнен'
                });
            } else {
                setError('password', {
                    type: 'server',
                    message: 'Сброс пароля не выполнен'
                });
            }
        }
    });

    function closeError(key: any) {
        clearErrors(key);
    }

    function closeFlashGreenAlert() {
        setIsSuccess(false);
    }

    return (
        <div>
            <>
                {(Object.keys(errors) as (keyof typeof errors)[]).map((key) => {
                    return errors?.[key] && errors?.[key]!.message &&
                        <FlashAlert key={key} message={errors?.[key]!.message} close={() => closeError(key)}/>
                })}
            </>
            { 
                isSuccess &&
                <FlashAlert message={'Email был отправлен'} close={closeFlashGreenAlert} isGreen={true} />
            }
            <div className="container layout">
                <main className="row row-mg0">
                    <div className="col-sm-12 content content-full content-left">
                        <div className="heading center"><h1>Восстановление пароля</h1></div>
                        <div className="login">
                        <form onSubmit={onSubmit} method="post" id="renewPasswordForm">
                            <div className="form-part">
                                <label>Пароль</label>
                                <input type="password" {...register('password')} name="password" id="frm-renewPasswordForm-password" />
                                <label>Подтвердить пароль</label>
                                <input type="password" {...register('passwordConfirmation')} name="passwordConfirmation" id="frm-renewPasswordForm-passwordVerify" />
                            </div>
                            <input type="submit" name="renewPassword" value="Обновить пароль" className="btn btn-secondary" />
                        </form>
                    </div>
                </div>
                </main>
            </div>
        </div>
    );
}

export default RecoveryPasswordPage;
