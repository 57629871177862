import React, { ReactElement, useEffect, useRef } from 'react';

import 'bootstrap/dist/css/bootstrap.css';
import { Category, StaticPages } from '../../types/ResponseTypes';

import CategoryMenu from './CategoryMenu/CategoryMenu';
import MainRow from './MainRow/MainRow';
import Navbar from './Navbar/Navbar';

type HeaderProps = {
    categories: Array<Category>
    menu: Array<StaticPages>
}

function Header({ categories, menu }: HeaderProps): ReactElement {
    const menuRef = useRef<HTMLDivElement>(null);

    function openMenu () {
        const element = menuRef.current;
        if (element?.className.includes('show')) {
            element?.classList.remove('show');
        } else {
            element?.classList.add('show');
        }
    }

    return (
        <header>
            <div className='container'>
                <Navbar menu={menu} />
                <MainRow openMenu={openMenu} />
                <CategoryMenu categories={categories} menuRef={menuRef} />
            </div>
	    </header>
    );
}
export default Header;
