import { ReactElement, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getOrders } from '../../api/LayoutApi';
import { useStore } from '../../store/store';
import { Order } from '../../types/ResponseTypes';
import OrderElement from './OrderElement/OrderElement';

function OrdersPage(): ReactElement {
    const { userStore } = useStore();
    const [orders, setOrders] = useState<Array<Order>>([]);

    useEffect(() => {
        const getData = async () => {
            const response = await getOrders(userStore.getJWT());
            setOrders(response.data);
        }
        getData();
    }, [])
    return (
        <div className="container layout">
            <main className="row">
                <div className="col-sm-12 content content-full content-left">
                    <div className="heading"><h1>Заказы</h1></div>
                    <div className="history">
                        <table className="table nice">
                            <tbody>
                                <tr>
                                    <th className="left">Номер заказа</th>
                                    <th className="left">Заказ создан</th>
                                    <th className="center">Стоимость за шт.</th>
                                    <th className="center">Статус заказа</th>
                                    <th className="center"></th>
                                    <th className="center"></th>
                                </tr>
                                {orders && orders.length > 0 && orders.map((order) => <OrderElement key={order.id} order={order} />)}
                            </tbody>
                        </table>
                    </div>
                    <p className="center">
                        <br/>
                        <Link className="btn btn-primary" to="/my-account">
                            <span>Назад</span>
                        </Link>
                    </p>
                </div>
            </main>
        </div>
    );
}

export default OrdersPage;
