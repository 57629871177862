import { CartItem, Customisation } from '../types/ResponseTypes';

export function getCustomisationText(cartItem: CartItem): string {
    if (cartItem.customisationref) {
        if (cartItem.product.jersey_customisable) {
            const text = cartItem.customisationref.text ? `${cartItem.customisationref.text},` : '';
            const number = cartItem.customisationref.number !== undefined && cartItem.customisationref.number !== null ?
                cartItem.customisationref.number : '';
            const sleevesNumber = cartItem.customisationref.sleeves_number ? ', номер на рукавах' : '';
            const captain = cartItem.customisationref.captain ? ', Отметка капитана' : '';
            const altCaptain = cartItem.customisationref.alt_captain ? ', Отметка ассистента' : '';
            const memorialRibbon = cartItem.customisationref.memorial_ribbon ? ', Памятная лента' : '';
            

            return `${text} ${number}${sleevesNumber}${captain}${altCaptain}${memorialRibbon}`;
        } else {
            const text = cartItem.customisationref.pennant_name ? `${cartItem.customisationref.pennant_name},` : '';
            const number = cartItem.customisationref.pennant_number !== undefined && cartItem.customisationref.pennant_number !== null ? 
                cartItem.customisationref.pennant_number : '';

            return `${text} ${number}`
        }
    }
    return ''
}

export function compareCustomisations(a: Customisation | undefined, b: Customisation | undefined): boolean {
    return (a?.alt_captain === b?.alt_captain &&
        a?.captain === b?.captain &&
        a?.memorial_ribbon === b?.memorial_ribbon &&
        a?.number === b?.number &&
        a?.sleeves_number === b?.sleeves_number &&
        a?.text === b?.text &&
        !a?.pennant_number && !b?.pennant_number &&
        !a?.pennant_name && !b?.pennant_name) ||
        (a?.pennant_name === b?.pennant_name &&
        a?.pennant_number === b?.pennant_number &&
        !a?.alt_captain && !b?.alt_captain &&
        !a?.captain && !b?.captain &&
        !a?.memorial_ribbon && !b?.memorial_ribbon &&
        !a?.number && !b?.number &&
        !a?.sleeves_number && !b?.sleeves_number &&
        !a?.text && !b?.text);
}
