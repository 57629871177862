import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useStore } from '../../../store/store';
import { DeliveryMethod } from '../../../types/ResponseTypes';

const DeliveryMethods = observer(() =>  {
    const [deliveryMethods, setDeliveryMethods] = useState<DeliveryMethod[]|undefined>()
    const { orderStore } = useStore();
    
    useEffect(() => {
        const getData = async () => {
            const data = await orderStore.loadDeliveryMethods();
            setDeliveryMethods(data.filter((method) => method.name !== 'DPD'));
        } 

        getData();
    }, [])

    return (
        <div className='delivery'>
            { deliveryMethods && <h2><label>Доставка</label></h2> }
            { deliveryMethods && deliveryMethods.map(item => {
                return (
                    <div className='item' key={item.id}>
                        <label>
                            <input 
                                type='radio' 
                                name='id_delivery' 
                                id={ `frm-deliveryForm-id_delivery-${item.id}` } 
                                value='{ item.id }' 
                                checked={ item.id == orderStore.currentOrder.delivery_method.id } 
                                onChange={e => {
                                    orderStore.currentOrder.delivery_method = item
                                }} />
                            { item.name }
                        </label>
                        {item.icon && <img className='custom-delivery-image' src={ item.icon } alt={ item.name } title={ item.name } /> }
                        <div className='desc'>{ item.description }</div>
                    </div>
            )})}
        </div>
    );
})

export default DeliveryMethods;


