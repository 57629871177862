import { AxiosResponse } from 'axios';
import { getURI, getUrlToImage } from '../api/ApiHelper';
import { tax } from '../constants/Constants';
import { BaseResponse, BaseCollectionResponse, SubCategoryDTO, CategoryDTO, Category, ProductDTO, CartItem, Product, DeliveryMethodDto, DeliveryMethod, PaymentMethodDto, PaymentMethod, OrderDetailDto, OrderDetail, OrderDetailPostDto, Customisation } from '../types/ResponseTypes'

export function mapBaseAndBaseCollectionResponse(obj: BaseResponse<any> | BaseCollectionResponse<any> | AxiosResponse<any, any>): any {
    return obj.data.data;
}

function mapSubcategory(obj: SubCategoryDTO) {
    return {
        id: obj.id,
        display_name: obj.attributes.display_name,
        name: obj.attributes.name,
        url: obj.attributes.url,
        display_order: obj.attributes.display_order,
    }
}

function mapCategory(obj: CategoryDTO) {
    return {
        ...mapSubcategory(obj),
        subcategories: obj.attributes.subcategories.data.map((subcategory) => mapSubcategory(subcategory))
    }
}

export function mapCategories(arr: Array<CategoryDTO>): Array<Category> {
    return arr.map((category) => mapCategory(category));
}

export function mapProductDtoToProduct1(arr: Array<any>, btnIsNeeded: boolean): Array<any> {
    return arr.map((el) => {
        const sticker: {text?: string, type?: string} = {};
        if (el.attributes.novelty) {
            sticker.text = 'Новинка';
            sticker.type = 'novelty';
        }
        if (el.attributes.sales) {
            sticker.text = 'Скидка';
            sticker.type = 'sales';
        }
        const image = {
            url: 'https://shop.hclokomotiv.ru/min.php?file=files/product/1/4/183/image.jpg&w=500&h=500',
            alt: el.attributes.common_name || el.attributes.name,
            type: 'image'
        }
        const body = {
            name: el.attributes.common_name || el.attributes.name,
            price: el.attributes.price,
            btnIsNeeded: btnIsNeeded
        }
        const id = el.id
        
        return { sticker, image, body, id };
    });
}

export function mapProductDtoToProduct(product: ProductDTO): Product {
    return {
        id: product.id,
        ...product.attributes,
        category: product.attributes.category.data ? {
            id: product.attributes.category.data.id,
            ...product.attributes.category.data.attributes
        } : undefined,
        subcategory: product.attributes.subcategory.data ? {
            id: product.attributes.subcategory.data.id,
            ...product.attributes.subcategory.data.attributes
        } : undefined,
        pictures: {
            data: [{
                url: 'https://shop.hclokomotiv.ru/min.php?file=files/product/1/1/3/original.jpg&w=1600&h=1200'
            }]
        }
    }
}

export function mapProductToCartItem(product: Product, positionPrice?: number, customisation?: Customisation): CartItem {
    return {
        amount: 1,
        id: product.id,
        product: JSON.parse(JSON.stringify(product)),
        tax: tax,
        position_price: positionPrice,
        picture_url: product.image?.main_image?.formats?.thumbnail.url || product.image?.main_image?.url || "",
        customisationref: customisation ? customisation : undefined
    }
}

export function mapOrderDetailDtoToOrderDetail(order: OrderDetailDto): OrderDetail {
    return {
        id: order.id,
        createdAt: order.createdAt,
        updatedAt: order.updatedAt,
        order_cost: order.order_cost,
        original_delivery_cost: order.delivery_cost,
        real_delivery_cost: order.delivery_cost,
        sum_paid: order.sum_paid,
        coupon_discount: order.coupon_discount,
        percent_discount: order.percent_discount,
        total_price_with_tax: order.total_price_with_tax,
        total_price_without_tax: order.total_price_without_tax,
        total_tax: order.total_tax,
        is_paid: order.is_paid,
        delivery_filled: false,
        delivery_name: order.delivery_name,
        delivery_street: order.delivery_street,
        delivery_building_number: order.delivery_building_number,
        delivery_flat_number: order.delivery_flat_number,
        delivery_city: order.delivery_city,
        delivery_postcode: order.delivery_postcode,
        payment_name: order.payment_name,
        payment_surname: order.payment_surname,
        payment_street: order.payment_street,
        payment_building_number: order.payment_building_number,
        payment_flat_number: order.payment_flat_number,
        payment_city: order.payment_city,
        payment_postcode: order.payment_postcode,
        notes: order.notes,
        phone: order.phone,
        email: order.email,
        delivery_method: order.delivery_method,
        delivery_country: order.delivery_country.id,
        order_status: order.order_status.id,
        tax: order.tax.id,
        payment_method: order.payment_method.id,
        payment_country: order.payment_country.id,
        bonus_to_spend: order.bonus_to_spend,
        order_items: order.order_items.map(e => e.id),
        delivery_address: order.address,
    }
}

export function mapOrderDetailToOrderDetailPostDto(order: OrderDetail): OrderDetailPostDto {
    return {
        order_cost: order.order_cost,
        delivery_cost: order.real_delivery_cost,
        total_price_with_tax: order.total_price_with_tax,
        delivery_filled: order.delivery_filled,
        delivery_name: order.delivery_name || `${order.payment_surname} ${order.payment_name}`,
        delivery_street: order.delivery_street || order.payment_street,
        delivery_building_number: order.delivery_building_number || order.payment_building_number,
        delivery_flat_number: order.delivery_flat_number || order.payment_flat_number,
        delivery_city: order.delivery_city || order.payment_city,
        delivery_postcode: order.delivery_postcode || order.payment_postcode,
        delivery_point: order.delivery_point,
        desired_delivery_point: order.desired_delivery_point,
        payment_name: order.payment_name,
        payment_surname: order.payment_surname,
        payment_street: order.payment_street,
        payment_building_number: order.payment_building_number,
        payment_flat_number: order.payment_flat_number,
        payment_city: order.payment_city,
        payment_postcode: order.payment_postcode,
        notes: order.notes,
        phone: order.phone,
        email: order.email,
        delivery_method: order.delivery_method.id,
        delivery_country: order.delivery_country || order.payment_country,
        order_status: order.order_status,
        tax: order.tax,
        payment_method: order.payment_method,
        payment_country: order.payment_country,
        bonus_to_spend: order.bonus_to_spend,
        order_items: order.order_items,
        delivery_address: order.delivery_address,
    }
}


export function mapDeliveryMethodDtoToDeliveryMethod(item: DeliveryMethodDto): DeliveryMethod {
    let description = ""
    let icon = ""

    switch (item.id) {
        case 1: 
            icon = getURI('/uploads/DPD_463e0afdcc.jpg?w=200&h=40')
            break

        case 2: 
            icon = getURI('/uploads/SDEK_88c6e81eb9.jpg?w=200&h=40')
            break

        case 3:
            icon = getURI('/uploads/Post_Russia_b5d7f5e632.jpg?w=200&h=40')
            break

        case 4:
            description = "Вы можете забрать оплаченный заказ в клубном магазине в «Арене 2000-Локомотив»."
            break
    }
    
    return {
        description,
        icon,
        id: item.id,
        name: item.attributes.name,
    }
}

export function mapPaymentMethodDtoToPaymentMethod(item: PaymentMethodDto): PaymentMethod {
    let description = ""

    switch (item.id) {
        case 1:
            description = "Вы можете оплатить заказ и его доставку банковской картой - VISA, MasterCard, МИР на нашем сайте. Оплата происходит через авторизационный сервер Процессингового центра Банка с использованием Банковских кредитных карт следующих платежных систем VISA International, MasterCard World Wide, МИР. Для оплаты покупки Вы будете перенаправлены на платежный шлюз ПАО «Сбербанк России» для ввода реквизитов Вашей карты: номер карты, дата окончания срока действия, имя и фамилия держателя карты, значения CVC2 или CVV2.  Пожалуйста, приготовьте Вашу пластиковую карту заранее. Соединение с платежным шлюзом и передача информации осуществляется в защищенном режиме с использованием протокола шифрования SSL."
            break

        case 2:
            description = "Вы можете оплатить заказ по банковским реквизитам Ассоциации ХК \"Локомотив\". После подтверждения заказа на вашу электронную будет выслан образец квитанции для заполнения."
            break
    }
    
    return {
        description,
        id: item.id,
        name: item.attributes.name,
    }
}
