import axios, { AxiosResponse } from 'axios';
import { Customisation, FilterData, OrderDetailDto, OrderDetailPostDto, SortData } from '../types/ResponseTypes';
import { getFilterParams, getPaginationParams, getSortParams, getURI } from './ApiHelper';

export async function getContacts() {
    return axios.get(getURI('/api/contacts-panel'));
}

export async function getStaticPages() {
    return axios.get(getURI('/api/static-pages'));
}
export async function getStaticPageById(id: number) {
    return axios.get(getURI(`/api/static-pages/${id}`));
}

export async function getExternalStaticPages() {
    return axios.get(getURI('/api/external-static-pages'));
}

export async function getCategories() {
    return axios.get(getURI('/api/categories?populate=*'));
}

export async function getFooter() {
    return axios.get(getURI('/api/footer'));
}

export async function getProductList() {
    return axios.get(getURI('/api/products/grouped'));
}

export function getProductsInGroup(groupId: string) {
    return axios.get(getURI(`/api/products/grouped/${groupId}?filters[availability][$gt]=0`)); 
}

export function getCustomisationElements() {
    return axios.get(getURI(`/api/customisation-mappings?populate=*`));
}

export async function getProductById(uuid_1c: string) {
    return axios.get(getURI(`/api/products/grouped/single/${uuid_1c}?populate=*`));
}

export async function getCategoryProducts(filters?: Array<FilterData>, sorts?: Array<SortData>, page?: number) {
    const filterParam = getFilterParams(filters);
    const sortParam = getSortParams(sorts);
    const paginationParam = getPaginationParams(page);
    return axios.get(getURI(`/api/products/grouped?${paginationParam}&${filterParam}&${sortParam}`));
}

export async function getCountProducts(filters?: Array<FilterData>) {
    const filterParam = getFilterParams(filters);
    return axios.get(getURI(`/api/products/grouped/count?${filterParam}`));
}

export async function getProductPrices(filters?: Array<FilterData>) {
    const filterParam = getFilterParams(filters);
    return axios.get(getURI(`/api/products/grouped/prices?${filterParam}`));
}

export async function getNovelties() {
    return axios.get(getURI(`/api/products/grouped?pagination[page]=0&pagination[pageSize]=12&filters[novelty][$eq]=true`));
}

export async function getTopWeek() {
    return axios.get(getURI(`/api/products/grouped?pagination[page]=0&pagination[pageSize]=12&filters[week_top_sale][$eq]=true`));
}

export async function getCountries() {
    return axios.get(getURI('/api/countries'));
}

export async function registration(data: any) {
    return axios.post(getURI('/api/auth/local/register'), data);
}

export async function login(data: any) {
    return axios.post(getURI('/api/auth/local'), data);
}

export async function forgotPassword(data: any) {
    return axios.post(getURI('/api/auth/forgot-password'), data);
}

export async function whoAmI(token: any) {
    return axios.get(getURI('/api/users/me?populate=*'), {
        headers: {
          Authorization: 'Bearer ' + token
        }
    });
}

export async function recoveryPassword(data: any) {
    return axios.post(getURI('/api/auth/reset-password'), data);
}

export async function updateProfile(data: any, token: any) {
    return axios.put(getURI(`/api/users/me`), data, {
        headers: {
          Authorization: 'Bearer ' + token
        }
    });
}

export async function addProductToCart(data: any, token: any) {
    return axios.post(getURI(`/api/users/cart-action`), data, {
        headers: {
          Authorization: 'Bearer ' + token
        }
    });
}

export async function updateProductAmount(data: any, token: any) {
    return axios.put(getURI(`/api/users/cart-action/${data.data.cartItemId}`), data, {
        headers: {
          Authorization: 'Bearer ' + token
        }
    });
}

export async function getCart(token: any) {
    return axios.get(getURI(`/api/users/cart`), {
        headers: {
          Authorization: 'Bearer ' + token
        }
    });
}

export async function deleteProductFromCart(id: number, token: any) {
    return axios.delete(getURI(`/api/users/cart-action/${id}`), {
        headers: {
          Authorization: 'Bearer ' + token
        }
    });
}

export async function getPaymentMethods(token: any) {
    return axios.get(getURI(`/api/payment-methods`), {
        headers: {
          Authorization: 'Bearer ' + token
        }
    });
}

export async function getDeliveryMethods(token: any) {
    return axios.get(getURI(`/api/delivery-methods`), {
        headers: {
          Authorization: 'Bearer ' + token
        }
    });
}

export async function getOrders(token: any) {
    return axios.get(getURI(`/api/orders?populate=*`), {
        headers: {
          Authorization: 'Bearer ' + token
        }
    });
}

export async function getOrderById(id: string, token: any) {
    return axios.get(getURI(`/api/orders/${id}`), {
        headers: {
          Authorization: 'Bearer ' + token
        }
    });
}

export async function postOrder(order: OrderDetailPostDto, token: any): Promise<AxiosResponse<OrderDetailDto>> {
    return axios.post(getURI(`/api/orders`), order, {
        headers: {
          Authorization: 'Bearer ' + token
        }
    });
}
export async function cancelOrder(id: number, token: any) {
    return axios.put(getURI(`/api/orders/cancel/${id}`), undefined, {
        headers: {
          Authorization: 'Bearer ' + token
        }
    });
}

export async function initPayment(orderId: number, token: any) {
    return axios.post(getURI(`/api/orders/payment`), {orderNumber: orderId}, {
        headers: {
          Authorization: 'Bearer ' + token
        }
    });
}

export async function getJerseyOptions() {
    return axios.get(getURI(`/api/products/grouped?filters[jersey_customisable][$eq]=true&pagination[limit]=-1`));
}

export async function getJerseyPrice(productId: number, customisation: Customisation | undefined) {
    if (!customisation) {
        return;
    }
    return axios.post(getURI(`/api/cart-items/get-price`), {product_id: productId, customisation: customisation});
}

export async function saveCustomisation(customisation: Customisation, token: any) {
    return axios.post(getURI(`/api/customisations`), {data: customisation}, {
        headers: {
          Authorization: 'Bearer ' + token
        }
    });
}

export async function getSdetPointsByPostCode(postCode: string, token: any) {
    return axios.get(getURI(`/api/orders/get-delivery-points?postal_code=${postCode}`), {
        headers: {
          Authorization: 'Bearer ' + token
        }
    });
}

export async function getDeliveryPrice(
    codeTo: string,
    deliveryId: number,
    country: number,
    token: any,
    weight: number | undefined,
    length: number | undefined,
    width: number | undefined,
    height: number | undefined,
    postal: string,
    address: string,
    tariff: any) {
    const getParams = `${codeTo ? `code_to=${codeTo}` : ''}` +
        `&delivery_id=${deliveryId}&country_to=${country}` +
        `${weight ? `&weight=${weight * 1000}` : ''}` +
        `${length ? `&length=${length}` : ''}` +
        `${width ? `&width=${width}` : ''}` +
        `${height ? `&height=${height}` : ''}` +
        `${postal ? `&postal=${postal}` : ''}` +
        `${address ? `&address=${address}` : ''}` +
        `${tariff ? `&tariff=${tariff}` : ''}`;
    return axios.get(getURI(`/api/orders/calculate-delivery-cost?${getParams}`), {
        headers: {
          Authorization: 'Bearer ' + token
        }
    });
}

export async function getFreeDeliveryPromotion(token: any) {
    return axios.get(getURI(`/api/free-delivery`), {
        headers: {
          Authorization: 'Bearer ' + token
        }
    });
}

export async function getFreeHomeDeliveryPromotion(token: any) {
    return axios.get(getURI(`/api/free-home-delivery`), {
        headers: {
          Authorization: 'Bearer ' + token
        }
    });
}

export async function getPointOfIssueOfOrders(cityName: string, token: any) {
    return axios.get(getURI(`/api/orders/get-dpd-points?cityName=${cityName}`), {
        headers: {
          Authorization: 'Bearer ' + token
        }
    });
}

export async function getBanners() {
    return axios.get(getURI('/api/banners?filters[type][type][$eq]=MainPage&populate=*'));
}

export async function getPopup() {
    return axios.get(getURI('/api/banners?filters[type][type][$eq]=PopUp&populate=*'));
}

export async function getBonusesBalance(token: any) {
    return axios.get(getURI(`/api/users/bonus-balance`), {
        headers: {
          Authorization: 'Bearer ' + token
        }
    });
}

export async function getBonusesPrecalc(token: any) {
    return axios.get(getURI(`/api/users/bonus-precalc`), {
        headers: {
          Authorization: 'Bearer ' + token
        }
    });
}

export async function getBonusSettings() {
    return axios.get(getURI('/api/bonus-manger'));
}
